export interface NavOptionType {
  path: string;
  label: string;
  alternatePaths: Array<string>;
  partial: Array<string>;
}
export const NAV_OPTIONS: Array<NavOptionType> = [
  { path: '/', label: 'Home', alternatePaths: [], partial: [] },
  { path: '/about', label: 'About Us', alternatePaths: [], partial: [] },
  { path: '/faqs', label: 'FAQs', alternatePaths: ['/tips'], partial: [] },
  {
    path: '/compare',
    label: 'Basic vs Custom',
    alternatePaths: [],
    partial: [],
  },
  { path: '/perc', label: 'PERC', alternatePaths: [], partial: ['/profile'] },
  // { path: '/optimize', label: 'Optimize', alternatePaths: [] , partial: []},
  { path: '/contact', label: 'Contact', alternatePaths: [], partial: [] },
];
