import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { E164Number } from 'libphonenumber-js/core';
import 'react-phone-number-input/style.css';
import PhoneInput, { Country } from 'react-phone-number-input';

import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';
import InputWrapper from '../InputWrapper';

const ST = {
  Input: styled.div<{
    color: string;
    fontFamily: string;
    textAlign: string;
    fontSize: string;
  }>`
    --PhoneInputCountryFlag-borderWidth: 0px;
    width: 100%;
    padding: ${PADDING.MD} ${PADDING.SM};

    input {
      border: 0px;

      background-color: ${COLORS.TRANSPARENT};
      color: ${p => p.color};
      text-align: ${p => p.textAlign};
      font-family: ${p => p.fontFamily};
      font-size: ${p => p.fontSize};

      :focus {
        outline: none;
        border: none;
      }

      :disabled {
        border: 0px;
        ${NO_SELECT}
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
      }
    }
  `,
};

interface Props {
  value: string;
  icon?: ReactNode;
  onChange?: (a: string) => void;
  textAlign?: string;
  fontSize?: string;
  fontFamily?: string;
  color?: string;
  disabled?: boolean;
  defaultCountry?: Country;
  placeholder?: string;
}

const CustomPhoneInput: React.FC<Props> = ({
  value,
  icon,
  onChange = () => {},
  textAlign = 'left',
  fontSize = FONT_SIZE.PX14,
  fontFamily = FONTS.NORMAL,
  color = COLORS.BLACK,
  disabled = false,
  defaultCountry = 'CA',
  placeholder,
}) => {
  const updateInput = (val: E164Number) => {
    onChange(`${val}`);
  };

  return (
    <InputWrapper leadingIcon={icon} hideBorder={disabled}>
      <ST.Input
        color={color}
        fontSize={fontSize}
        fontFamily={fontFamily}
        textAlign={textAlign}
        // disabled={disabled}
      >
        <PhoneInput
          placeholder={placeholder}
          disabled={disabled}
          value={value as E164Number}
          onChange={updateInput}
          addInternationalOption={false}
          defaultCountry={defaultCountry}
        />
      </ST.Input>
    </InputWrapper>
  );
};

export default memo(CustomPhoneInput);
