/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getPreviousResultsThunk, getUsageThunk } from './thunks';
import { getUsageResponseType } from './apis';

interface DownloadPageState {
  pending: boolean;
  usage: {
    basic: number;
    custom: { complete: number; pending: number };
    subscriptions: { active: number; inactive: number };
  };
}

const initialState: DownloadPageState = {
  pending: false,
  usage: {
    basic: 0,
    custom: { complete: 0, pending: 0 },
    subscriptions: { active: 0, inactive: 0 },
  },
};

const slice = createSlice({
  name: 'downloadPage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUsageThunk.pending, (state: DownloadPageState) => {
      state.pending = true;
    });
    builder.addCase(
      getUsageThunk.fulfilled,
      (
        state: DownloadPageState,
        action: PayloadAction<getUsageResponseType>,
      ) => {
        state.pending = false;
        state.usage = action.payload;
      },
    );
    builder.addCase(getUsageThunk.rejected, (state: DownloadPageState) => {
      state.pending = false;
    });
    builder.addCase(
      getPreviousResultsThunk.pending,
      (state: DownloadPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getPreviousResultsThunk.fulfilled,
      (
        state: DownloadPageState,
        // action: PayloadAction<getPreviousResultsResponseType>,
      ) => {
        state.pending = false;
      },
    );
    builder.addCase(
      getPreviousResultsThunk.rejected,
      (state: DownloadPageState) => {
        state.pending = false;
      },
    );
  },
});

// export const {} = slice.actions;

export default slice.reducer;
