import { findIndex } from 'lodash-es';

type ToggleObjArrayType = Record<string, any>;

export default (
  arr: Array<ToggleObjArrayType>,
  toggleOn: string,
  val: ToggleObjArrayType,
) => {
  const tempIndex = findIndex(arr, d => d[toggleOn] === val[toggleOn]);
  const arrCopy = [...arr];
  // eslint-disable-next-line no-unused-expressions
  tempIndex > -1 ? arrCopy.splice(tempIndex, 1) : arrCopy.push(val);

  return arrCopy;
};
