/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSubscriptionPricingThunk, orderSubscriptionThunk } from './thunks';

export interface SubscriptionPricingType {
  label: string;
  amount: number;
  hst: number;
  total: number;
  paymentValue: number;
}

export interface SubscriptionPageState {
  pending: boolean;
  paymentModal: { isOpen: boolean };
}

const initialState: SubscriptionPageState = {
  pending: false,
  paymentModal: { isOpen: false },
};

const slice = createSlice({
  name: 'subscriptionsPage',
  initialState,
  reducers: {
    openPaymentModalAction: (state: SubscriptionPageState) => {
      state.paymentModal = { isOpen: true };
    },
    closePaymentModalAction: (state: SubscriptionPageState) => {
      state.paymentModal = { isOpen: false };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      orderSubscriptionThunk.pending,
      (state: SubscriptionPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      orderSubscriptionThunk.fulfilled,
      (state: SubscriptionPageState) => {
        state.pending = false;
        state.paymentModal = { isOpen: false };
      },
    );
    builder.addCase(
      orderSubscriptionThunk.rejected,
      (state: SubscriptionPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      getSubscriptionPricingThunk.pending,
      (state: SubscriptionPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getSubscriptionPricingThunk.fulfilled,
      (state: SubscriptionPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      getSubscriptionPricingThunk.rejected,
      (state: SubscriptionPageState) => {
        state.pending = false;
      },
    );
  },
});

export const { openPaymentModalAction, closePaymentModalAction } =
  slice.actions;

export default slice.reducer;
