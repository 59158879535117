import { ERRORS, ScreenKeyType } from '@constants/error_messages';
import {
  attemptForceLoginAction,
  openGlobalErrorModalAction,
} from '@containers/App/state/reducer';

export const processThunkError = async (
  err: any,
  dispatch: any,
  screen: ScreenKeyType,
  skipMainError?: boolean,
) => {
  let errorReject;
  if (err.code) {
    if (err.code === 'INVALID_TOKEN') {
      dispatch(attemptForceLoginAction());
    } else {
      if (!skipMainError) {
        dispatch(openGlobalErrorModalAction(ERRORS(screen, err.code)));
      }
      errorReject = err.code;
    }
  } else {
    dispatch(openGlobalErrorModalAction(ERRORS(screen, 'MAJOR')));
  }
  return errorReject;
};
