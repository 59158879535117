import React, { memo } from 'react';
import styled from 'styled-components';
import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';
import RawText, { RawTextProps } from '../RawText';

const ST = {
  Wrapper: styled.div<{ hideMargin: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${p => (p.hideMargin ? '0px' : PADDING.MD)} 0px;
  `,
  OrderedList: styled.ol<{ lowercaseLetter: boolean }>`
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 20px;
    ${p =>
      p.lowercaseLetter
        ? `
          counter-reset: list;
          li {
            list-style: none;
            position: relative;
          }

          li:before {
            counter-increment: list;
            content: counter(list, lower-alpha) ") ";
            position: absolute;
            left: -1.3em;
          }
        `
        : ''}
  `,
  UnorderedList: styled.ul`
    margin-top: 0px;
    margin-bottom: 0px;
  `,
  ListItem: styled.li<{
    hideMargin: boolean;
    textAlign: string;
    selectable: boolean;
    color: string;
    fontSize: string;
    fontFamily: string;
  }>`
    width: 100%;
    margin-bottom: ${p => (p.hideMargin ? '0px' : PADDING.XS)};
    text-align: ${p => p.textAlign};
    color: ${p => p.color};
    font-size: ${p => p.fontSize};
    font-family: ${p => p.fontFamily};

    ${p => (p.selectable ? '' : NO_SELECT)}
  `,
};

export interface ListTextProps extends RawTextProps {
  listColor?: string;
  listFontSize?: string;
  listFontFamily?: string;
}
interface Props {
  items: Array<Array<ListTextProps>>;
  hideMargin?: boolean;
  textAlign?: string;
  selectable?: boolean;
  bulletType?: 'plain_list' | 'numbered_list' | 'lowercase_letter_list';
}

const ListText: React.FC<Props> = ({
  items,
  hideMargin = false,
  textAlign = 'left',
  selectable = false,
  bulletType = 'plain_list',
}) => {
  const ListHolder = ['numbered_list', 'lowercase_letter_list'].includes(
    bulletType,
  )
    ? ST.OrderedList
    : ST.UnorderedList;

  return (
    <ST.Wrapper hideMargin>
      <ListHolder
        lowercaseLetter={false}
        type={bulletType === 'lowercase_letter_list' ? 'a' : '1'}
        // lowercaseLetter={bulletType === 'lowercase_letter_list'}
      >
        {items.map((d, i) => (
          <ST.ListItem
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            hideMargin={hideMargin}
            textAlign={textAlign}
            selectable={selectable}
            color={d[0].listColor || COLORS.BLACK}
            fontSize={d[0].listFontSize || FONT_SIZE.PX14}
            fontFamily={d[0].listFontFamily || FONTS.NORMAL}
          >
            <RawText text={d} textAlign={textAlign} selectable={selectable} />
          </ST.ListItem>
        ))}
      </ListHolder>
    </ST.Wrapper>
  );
};

export default memo(ListText);
