import React, { memo } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Tooltip,
  TooltipItem,
  Legend,
} from 'chart.js';

import { COLORS } from '@constants/styles';
import GraphTitle from '../GraphTitle';

const ST = {
  Wrapper: styled.div<{ height: number }>`
    height: ${p => p.height}px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  Container: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  ChartWrapper: styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    overflow: hidden;
  `,
  ChartContainer: styled.div`
    height: 100%;
    width: 100%;
    position: relative;
  `,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
);

interface Props {
  xLabels: Array<string | number | Date>;
  data: Array<{
    label: string;
    data: Array<number> | Array<{ [key: string]: number | string }>;
    color?: string;
  }>;
  title?: string;
  subtitle?: string;
  height?: number;
  lineTension?: number;
  xAxisTitle?: string;
  yAxisTitle?: string;
  hideLegend?: boolean;
  tooltipTitleCallback?: (context: Array<TooltipItem<'line'>>) => void;
  tooltipLabelCallback?: (context: TooltipItem<'line'>) => void;
  loadImage?: boolean;
}

const LineGraph: React.FC<Props> = ({
  xLabels,
  data,
  title = '',
  subtitle = '',
  height = 400,
  lineTension = 0,
  xAxisTitle = undefined,
  yAxisTitle = undefined,
  hideLegend = false,
  tooltipTitleCallback = () => {},
  tooltipLabelCallback = () => {},
  loadImage = false,
}) => {
  const chartData = {
    labels: xLabels,
    datasets: data.map((d, i) => ({
      label: d.label,
      data: d.data,
      fill: false,
      borderColor: d.color || COLORS.RANGE[i],
      tension: lineTension,
    })),
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    ...(loadImage
      ? {
          devicePixelRatio: 4,
        }
      : {}),
    scales: {
      x: {
        title: {
          display: xAxisTitle !== undefined,
          text: xAxisTitle,
          color: COLORS.BLACK,
        },
        ticks: { color: COLORS.BLACK },
      },
      y: {
        title: {
          display: yAxisTitle !== undefined,
          text: yAxisTitle,
          color: COLORS.BLACK,
        },
        ticks: { color: COLORS.BLACK },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: !hideLegend,
        position: 'bottom',
        labels: {
          boxHeight: 1,
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        boxHeight: 1,
        callbacks: {
          title: tooltipTitleCallback,
          label: tooltipLabelCallback,
        },
      },
    },
  };

  return (
    <ST.Wrapper height={height}>
      <ST.Container>
        {(title !== '' || subtitle !== '') && (
          <GraphTitle title={title} subtitle={subtitle} />
        )}
        <ST.ChartWrapper>
          <ST.ChartContainer>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Line options={chartOptions} data={chartData} />
          </ST.ChartContainer>
        </ST.ChartWrapper>
      </ST.Container>
    </ST.Wrapper>
  );
};

export default memo(LineGraph);
