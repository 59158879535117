import React, { memo, useEffect } from 'react';
import Select, {
  components,
  CSSObjectWithLabel,
  OptionProps,
} from 'react-select';
import { isEqual } from 'lodash';

import toggleObjArray from '@utils/fcns/toggleObjArray';
import { COLORS, PADDING, ZINDEX } from '@constants/styles';
import usePrevious from '@customHooks/usePrevious';

import { DropdownOptionType } from '../types';

interface Props {
  options: Array<DropdownOptionType>;
  selected: Array<DropdownOptionType>;
  updateSelected: (a: Array<DropdownOptionType>) => void;
  forceAllDefault?: boolean;
}

const CheckboxOption: React.FC<OptionProps> = props => (
  <div>
    <components.Option {...props}>
      <input
        type={'checkbox'}
        checked={props.isSelected}
        onChange={() => null}
        style={{ marginRight: PADDING.SM }}
      />
      <label>{props.label}</label>
    </components.Option>
  </div>
);

const MultiSelectDropdown: React.FC<Props> = ({
  options,
  selected,
  updateSelected,
  forceAllDefault = true,
}) => {
  const selectionChanged = (val: any) => {
    updateSelected(
      toggleObjArray(
        [...(selected as Array<DropdownOptionType>)],
        'value',
        val,
      ) as Array<DropdownOptionType>,
    );
  };

  const formatDisplayText = (label: string) => {
    if (selected.length === options.length) {
      return 'All';
    }

    if (selected.length > 1) {
      return `${selected.length} selected`;
    }

    return label;
  };

  const prevOptions = usePrevious(options);

  useEffect(() => {
    if (!isEqual(options, prevOptions) && forceAllDefault) {
      updateSelected(options);
    }
  }, [options, prevOptions, forceAllDefault]);

  return (
    <Select
      options={options}
      //   isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option: CheckboxOption,
      }}
      onChange={selectionChanged}
      value={selected}
      isClearable={false}
      formatOptionLabel={(p: any) => formatDisplayText(p.label)}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: base =>
          ({ ...base, zIndex: ZINDEX.EXTRA_HIGH } as CSSObjectWithLabel),
        option: styles =>
          ({
            ...styles,
            cursor: 'pointer',
          } as CSSObjectWithLabel),
        control: styles =>
          ({
            ...styles,
            cursor: 'pointer',
            minHeight: '48px',
            border: `1px solid ${COLORS.BORDER_GRAY}`,
          } as CSSObjectWithLabel),
        indicatorSeparator: styles =>
          ({
            ...styles,
            backgroundColor: COLORS.BORDER_GRAY,
          } as CSSObjectWithLabel),
        dropdownIndicator: styles =>
          ({
            ...styles,
            color: COLORS.BORDER_GRAY,
          } as CSSObjectWithLabel),
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: COLORS.LIGHT_GRAY,
          primary: COLORS.MAIN,
        },
      })}
    />
  );
};

export default memo(MultiSelectDropdown);
