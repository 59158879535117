import React, { memo } from 'react';
import styled from 'styled-components';
import { BsToggle2Off, BsToggle2On } from 'react-icons/bs';
import { COLORS, FONT_SIZE } from '@constants/styles';

const ST = {
  Wrapper: styled.div<{
    selected: boolean;
    fontSize: string;
    emptyColor: string;
    selectedColor: string;
    highlightColor: string;
  }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${p => p.fontSize};
    color: ${p => (p.selected ? p.selectedColor : p.emptyColor)};
    cursor: pointer;

    :hover {
      color: ${p => p.highlightColor};
    }
  `,
};

interface Props {
  val: boolean;
  updateVal: (a: boolean) => void;
  fontSize?: string;
  emptyColor?: string;
  selectedColor?: string;
  highlightColor?: string;
}

const CustomSwitch: React.FC<Props> = ({
  val,
  updateVal,
  fontSize = FONT_SIZE.PX18,
  emptyColor = COLORS.SILVER,
  selectedColor = COLORS.MAIN,
  highlightColor = COLORS.ORANGE,
}) => {
  const toggleValue = () => {
    updateVal(!val);
  };
  return (
    <ST.Wrapper
      onClick={toggleValue}
      selected={val}
      fontSize={fontSize}
      emptyColor={emptyColor}
      selectedColor={selectedColor}
      highlightColor={highlightColor}
    >
      {val ? <BsToggle2On /> : <BsToggle2Off />}
    </ST.Wrapper>
  );
};

export default memo(CustomSwitch);
