import React, { memo, ReactNode, useRef } from 'react';
import styled from 'styled-components';
import useSize from '@react-hook/size';

import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${NO_SELECT}
  `,

  Button: styled.div<{
    round?: boolean;
    width?: number;
    horizontalMargin: string;
    verticalMargin: string;
    horizontalPadding: string;
    verticalPadding: string;
    disabledBtn: boolean;
    bkg: string;
    hoverBkg: string;
    color: string;
    hoverColor: string;
    border: string;
    borderColor: string;
    hoverBorderColor: string;
    borderRadius: string;
    cursor: string;
    hideLeftMargin: boolean;
    hideRightMargin: boolean;
  }>`
    background-color: ${p => p.bkg};
    color: ${p => p.color};
    border: ${p => p.border} solid ${p => p.borderColor};
    height: 100%;
    ${p => (p.width ? `width: ${p.width}px; max-width: ${p.width}px;` : '')}
    border-radius:${p => (p.round ? '100%' : p.borderRadius)};
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: ${p => p.cursor};
    margin: ${p => p.verticalMargin} ${p => p.horizontalMargin};
    padding: ${p => p.verticalPadding} ${p => p.horizontalPadding};
    ${p => (p.disabledBtn ? 'pointer-events: none;' : '')}

    ${p => (p.hideLeftMargin ? 'margin-left: 0px;' : '')}
    ${p => (p.hideRightMargin ? 'margin-right: 0px;' : '')}

    path {
      stroke: ${p => p.color};
    }

    :hover {
      background-color: ${p => p.hoverBkg};
      color: ${p => p.hoverColor};
      border: ${p => p.border} solid ${p => p.hoverBorderColor};
      path {
        stroke: ${p => p.hoverColor};
      }
    }
  `,
  ButtonText: styled.div<{
    fontFamily: string;
    fontSize: string;
    textAlign: string;
    whiteSpace: string;
  }>`
    height: 100%;
    width: 100%;
    font-family: ${p => p.fontFamily};
    font-size: ${p => p.fontSize};
    display: flex;
    flex-direction: row;
    justify-content: ${p => p.textAlign};
    align-items: center;
    text-align: ${p => p.textAlign};
    white-space: ${p => p.whiteSpace};
  `,
  ButtonIcon: styled.div<{
    addLeftMargin?: boolean;
    addRightMargin?: boolean;
    iconPadding: string;
  }>`
    margin-left: ${p => (p.addLeftMargin ? p.iconPadding : '0px')};
    margin-right: ${p => (p.addRightMargin ? p.iconPadding : '0px')};
    font-size: ${FONT_SIZE.PX26};
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
};

export type ButtonStylesType =
  | 'MAIN'
  | 'MAIN_INVERTED'
  | 'INVERTED'
  | 'TRANSPARENT'
  | 'INVERTED_TRANSPARENT'
  | 'SKELETON'
  | 'DANGER'
  | 'DISABLED';
export interface BUTTON_TYPE {
  key: string;
  fcn: () => void;
  icon?: ReactNode;
  text?: string;
  style: ButtonStylesType;
  custom?: {
    bkg?: string;
    color?: string;
    hoverBkg?: string;
    hoverColor?: string;
    hoverBorderColor?: string;
    fontFamily?: string;
    fontSize?: string;
    textAlign?: string;
    horizontalPadding?: string;
    verticalPadding?: string;
    horizontalMargin?: string;
    verticalMargin?: string;
    border?: number;
    borderColor?: string;
    borderRadius?: string;
    iconAfter?: boolean;
    iconPadding?: string;
    cursor?: string;
    whiteSpace?: string;
    overrideWidth?: number;
    hideOuterMargin?: boolean;
  };
  round?: boolean;
  disabled?: boolean;
}
interface Props {
  buttons: Array<BUTTON_TYPE>;
}

const commonDefaults = {
  fontFamily: FONTS.SEMI_BOLD,
  fontSize: FONT_SIZE.PX14,
  textAlign: 'center',
  horizontalPadding: PADDING.MD,
  verticalPadding: PADDING.SSM,
  horizontalMargin: undefined,
  verticalMargin: '0px',
  border: 0,
  borderColor: COLORS.MAIN,
  borderRadius: '6px',
  iconAfter: false,
  iconPadding: PADDING.SM,
  cursor: 'pointer',
  whiteSpace: 'inherit',
};

const defaultStyles = {
  MAIN: {
    ...commonDefaults,
    bkg: COLORS.MAIN,
    color: COLORS.WHITE,
    borderColor: COLORS.MAIN,
    hoverBkg: COLORS.BLACK,
    hoverColor: COLORS.WHITE,
    hoverBorderColor: COLORS.BLACK,
  },
  MAIN_INVERTED: {
    ...commonDefaults,
    bkg: COLORS.MAIN,
    color: COLORS.WHITE,
    borderColor: COLORS.MAIN,
    hoverBkg: COLORS.WHITE,
    hoverColor: COLORS.MAIN,
    hoverBorderColor: COLORS.WHITE,
  },
  INVERTED: {
    ...commonDefaults,
    bkg: COLORS.WHITE,
    color: COLORS.MAIN,
    borderColor: COLORS.WHITE,
    hoverBkg: COLORS.MAIN,
    hoverColor: COLORS.WHITE,
    hoverBorderColor: COLORS.MAIN,
  },
  TRANSPARENT: {
    ...commonDefaults,
    bkg: COLORS.TRANSPARENT,
    color: COLORS.MAIN,
    borderColor: COLORS.TRANSPARENT,
    hoverBkg: COLORS.TRANSPARENT,
    hoverColor: COLORS.BLACK,
    hoverBorderColor: COLORS.TRANSPARENT,
  },
  SKELETON: {
    ...commonDefaults,
    bkg: COLORS.TRANSPARENT,
    color: COLORS.BLACK,
    hoverBkg: COLORS.BLACK,
    hoverColor: COLORS.WHITE,
    border: 1,
    borderColor: COLORS.BLACK,
    hoverBorderColor: COLORS.BLACK,
  },
  INVERTED_TRANSPARENT: {
    ...commonDefaults,
    bkg: COLORS.TRANSPARENT,
    color: COLORS.BLACK,
    borderColor: COLORS.TRANSPARENT,
    hoverBkg: COLORS.TRANSPARENT,
    hoverColor: COLORS.MAIN,
    hoverBorderColor: COLORS.TRANSPARENT,
  },
  DANGER: {
    ...commonDefaults,
    bkg: COLORS.DARK_RED,
    color: COLORS.WHITE,
    borderColor: COLORS.DARK_RED,
    hoverBkg: COLORS.BLACK,
    hoverColor: COLORS.WHITE,
    hoverBorderColor: COLORS.BLACK,
  },
  DISABLED: {
    ...commonDefaults,
    bkg: COLORS.GRAY,
    color: COLORS.DARK_GRAY,
    borderColor: COLORS.GRAY,
    hoverBkg: COLORS.GRAY,
    hoverColor: COLORS.DARK_GRAY,
    hoverBorderColor: COLORS.GRAY,
  },
};

const MultiButtonRow: React.FC<Props> = ({ buttons }) => {
  const wrapperRef = useRef(null);
  const [, height] = useSize(wrapperRef);

  return (
    <ST.Wrapper ref={wrapperRef}>
      {buttons.map((d, i) => {
        const btnStyle = {
          ...defaultStyles.MAIN,
          ...defaultStyles[d.style],
          ...(d.custom || {}),
        };

        return (
          <ST.Button
            key={d.key}
            onClick={d.fcn}
            round={d.round}
            // width={height}
            width={
              d.round || d.custom?.overrideWidth
                ? d.custom?.overrideWidth || height
                : undefined
            }
            bkg={btnStyle.bkg}
            color={btnStyle.color}
            hoverBkg={btnStyle.hoverBkg}
            hoverColor={btnStyle.hoverColor}
            hoverBorderColor={btnStyle.hoverBorderColor}
            border={`${btnStyle.border}px`}
            borderColor={btnStyle.borderColor}
            borderRadius={btnStyle.borderRadius}
            horizontalMargin={
              // eslint-disable-next-line no-nested-ternary
              btnStyle.horizontalMargin === undefined
                ? buttons.length > 1
                  ? PADDING.SM
                  : '0px'
                : btnStyle.horizontalMargin
            }
            verticalMargin={btnStyle.verticalMargin}
            horizontalPadding={btnStyle.horizontalPadding}
            verticalPadding={btnStyle.verticalPadding}
            cursor={btnStyle.cursor}
            disabledBtn={d.disabled || false}
            hideLeftMargin={(btnStyle.hideOuterMargin || false) && i === 0}
            hideRightMargin={
              (btnStyle.hideOuterMargin || false) && i === buttons.length - 1
            }
          >
            {(d.text || d.icon) && (
              <ST.ButtonText
                fontFamily={btnStyle.fontFamily}
                fontSize={btnStyle.fontSize}
                textAlign={btnStyle.textAlign}
                whiteSpace={btnStyle.whiteSpace}
              >
                {d.icon && !btnStyle.iconAfter && (
                  <ST.ButtonIcon
                    addRightMargin={![undefined, '', null].includes(d.text)}
                    iconPadding={btnStyle.iconPadding || PADDING.SM}
                  >
                    {d.icon}
                  </ST.ButtonIcon>
                )}
                {d.text}
                {d.icon && btnStyle.iconAfter && (
                  <ST.ButtonIcon
                    addLeftMargin={![undefined, '', null].includes(d.text)}
                    iconPadding={btnStyle.iconPadding || PADDING.SM}
                  >
                    {d.icon}
                  </ST.ButtonIcon>
                )}
              </ST.ButtonText>
            )}
          </ST.Button>
        );
      })}
    </ST.Wrapper>
  );
};

export default memo(MultiButtonRow);
