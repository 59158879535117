import React, { memo } from 'react';
import styled from 'styled-components';
import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';

const ST = {
  Wrapper: styled.div<{ direction: 'row' | 'column' }>`
    display: flex;
    flex-direction: ${p => p.direction};
  `,
  Option: styled.div<{ color: string; labelColor: string; disabled: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${PADDING.XS};
    color: ${p => p.labelColor};
    min-height: 30px;
    cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

    accent-color: ${p => p.color};
  `,
  Radio: styled.input`
    margin-right: 5px;
  `,
  LabelWrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    ${NO_SELECT}
  `,
  Label: styled.div<{ fontSize: string }>`
    font-family: ${FONTS.NORMAL};
    font-size: ${p => p.fontSize};
  `,
  Description: styled.div<{ fontSize: string }>`
    font-family: ${FONTS.NORMAL};
    font-size: ${p => p.fontSize};
  `,
};

interface Props {
  data: Array<{
    label: string;
    key: string | number;
    labelColor?: string;
    description?: string;
  }>;
  selected: string;
  optionChanged: (a: string) => void;
  direction?: 'row' | 'column';
  color?: string;
  fontSize?: string;
  disabled?: boolean;
}

const RadioGroup: React.FC<Props> = ({
  data,
  selected,
  optionChanged,
  direction = 'column',
  color = COLORS.MAIN,
  fontSize = FONT_SIZE.PX16,
  disabled = false,
}) => {
  const updateSelection = (ev: any) => {
    if (!disabled) {
      optionChanged(ev.target.value);
    }
  };

  const optionClicked = (val: string) => {
    if (!disabled) {
      optionChanged(val);
    }
  };
  return (
    <ST.Wrapper direction={direction}>
      {data.map(d => (
        <ST.Option
          key={d.key}
          color={color}
          labelColor={disabled ? COLORS.GRAY : d.labelColor || COLORS.BLACK}
          disabled={disabled}
        >
          <ST.Radio
            type={'radio'}
            value={d.key}
            checked={selected === d.key}
            onChange={updateSelection}
            disabled={disabled}
          />
          <ST.LabelWrapper
            onClick={() => {
              optionClicked(d.key as string);
            }}
          >
            <ST.Label fontSize={fontSize}>{d.label}</ST.Label>
            {d.description && (
              <ST.Description fontSize={fontSize}>
                {d.description}
              </ST.Description>
            )}
          </ST.LabelWrapper>
        </ST.Option>
      ))}
    </ST.Wrapper>
  );
};

export default memo(RadioGroup);
