import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { FONTS, FONT_SIZE } from '@constants/styles';

const ST = {
  Text: styled.div<{ fontSize: string; lineHeight: string }>`
    font-family: ${FONTS.NORMAL};
    font-size: ${p => p.fontSize};
    color: ${p => p.color};
    line-height: ${p => p.lineHeight};
  `,
};
interface Props {
  text: string | ReactNode;
  color: string;
  fontSize?: string;
  lineHeight?: string;
}
const SectionText: React.FC<Props> = ({
  text,
  color,
  fontSize = FONT_SIZE.PX16,
  lineHeight = FONT_SIZE.PX24,
}) => (
  <ST.Text color={color} fontSize={fontSize} lineHeight={lineHeight}>
    {text}
  </ST.Text>
);

export default memo(SectionText);
