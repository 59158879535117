import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { COLORS } from '@constants/styles';

const ST = {
  Wrapper: styled.div<{ hideBorder: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    border: ${p => (p.hideBorder ? 0 : 1)}px solid rgba(0, 0, 0, 0.42);
    border-radius: 4px;

    :focus-within {
      border: 2px solid ${COLORS.MAIN};
    }
  `,
  Icon: styled.div`
    padding-left: 8px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${COLORS.GRAY};
  `,
};

interface Props {
  children: ReactNode;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  hideBorder?: boolean;
}

const InputWrapper: React.FC<Props> = ({
  children,
  leadingIcon,
  trailingIcon,
  hideBorder = false,
}) => (
  <ST.Wrapper hideBorder={hideBorder}>
    {leadingIcon && <ST.Icon>{leadingIcon}</ST.Icon>}
    {children}
    {trailingIcon && <ST.Icon>{trailingIcon}</ST.Icon>}
  </ST.Wrapper>
);

export default memo(InputWrapper);
