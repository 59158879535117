import React, { memo } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import styled from 'styled-components';

import { TableColumnType } from '@components/CustomTable/types';
import { COLORS } from '@constants/styles';
import { formatByType } from '@utils/formatting';

import CustomForm from '@components/CustomForm';
import RenderImage from '@components/RenderImage';

const ST = {
  ImageWrapper: styled.div`
    height: 50px;
    width: 50px;
  `,
  CheckboxWrapper: styled.div<{
    size: number;
    flexAlign?: 'flex-start' | 'center' | 'flex-end';
  }>`
    display: flex;
    flex-direction: row;
    justify-content: ${p => p.flexAlign || 'center'};
    align-items: center;
  `,
  ListWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ListItem: styled.div``,
};

interface Props {
  type: TableColumnType['type'] | 'title';
  val: any;
}

const TableCellRenderer: React.FC<Props> = ({ type, val }) => {
  switch (type) {
    case 'image':
      return (
        <ST.ImageWrapper>
          <RenderImage image={val} />
        </ST.ImageWrapper>
      );
    case 'checkbox':
      return (
        <ST.CheckboxWrapper
          size={val.size}
          onClick={val.clickFcn}
          flexAlign={val.flexAlign}
        >
          {val.value ? (
            <MdCheckBox size={35} color={COLORS.MAIN} />
          ) : (
            <MdCheckBoxOutlineBlank size={35} color={COLORS.GRAY} />
          )}
        </ST.CheckboxWrapper>
      );
    case 'list':
      return (
        <ST.ListWrapper>
          {val.map((d: { label: string }, i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <ST.ListItem key={i}>{d.label}</ST.ListItem>
          ))}
        </ST.ListWrapper>
      );
    case 'time':
      return val
        ? `${formatByType({
            type: 'simple_time',
            val: new Date(`06/01/2022T${val}`),
          })}`
        : '-';
    case 'date':
      return `${formatByType({ type: 'date', val })}`;
    case 'icon':
      return val.icon;
    case 'input':
      return (
        <CustomForm options={val} hideSave validationFcn={() => ({})} inTable />
      );
    case 'spacer':
      return null;
    default:
      return `${formatByType({ type, val })}`;
  }
};

export default memo(TableCellRenderer);
