import React, { memo, useRef } from 'react';
import styled from 'styled-components';
import useSize from '@react-hook/size';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { COLORS, PADDING, SHADOW_GENERATOR_OBJ } from '@constants/styles';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    padding: ${PADDING.MD};
  `,
  Container: styled.div`
    height: 100%;
    width: 100%;

    .rc-slider-disabled {
      background-color: ${COLORS.TRANSPARENT};
    }
  `,
};

interface Props {
  value: number;
  updateValue: (a: number) => void;
  min?: number;
  max?: number;
  step?: number;
  trackColor?: string;
  railColor?: string;
  handleOverhang?: number;
  disabled?: boolean;
}

const CustomSlider: React.FC<Props> = ({
  value,
  updateValue,
  min = 0,
  max = 10,
  step = 1,
  trackColor = COLORS.MAIN,
  railColor = COLORS.GRAY,
  handleOverhang = 5,
  disabled = false,
}) => {
  const containerRef = useRef(null);
  const [, height] = useSize(containerRef);

  const sliderUpdated = (val: number | Array<number>) => {
    updateValue(val as number);
  };

  return (
    <ST.Wrapper>
      <ST.Container ref={containerRef}>
        <Slider
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={sliderUpdated}
          allowCross={false}
          trackStyle={{
            backgroundColor: disabled ? COLORS.GRAY : trackColor,
            height: `${height}px`,
          }}
          handleStyle={{
            backgroundColor: disabled ? COLORS.DARK_GRAY : COLORS.WHITE,
            opacity: 1,
            borderColor: COLORS.BLACK,
            borderWidth: '1px',
            height: `${height + 2 * handleOverhang}px`,
            marginTop: `${-handleOverhang}px`,
            borderRadius: '4px',
            ...SHADOW_GENERATOR_OBJ(1, 1, 2),
          }}
          railStyle={{
            backgroundColor: railColor,
            height: `${height}px`,
          }}
          disabled={disabled}
        />
      </ST.Container>
    </ST.Wrapper>
  );
};

export default memo(CustomSlider);
