import { createAsyncThunk } from '@reduxjs/toolkit';

import { processThunkError } from '@utils/processThunkError';
import { genericTokenCheck } from '@containers/App/state/thunks';
import UserPageAPIs, {
  GetUserPageType,
  UpdateProfileNumType,
  UpdateUserDetailsType,
} from './apis';

export const getUserPageThunk = createAsyncThunk(
  'userPage/getUserPage',
  async (payload: GetUserPageType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await UserPageAPIs.getUserPageAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'USER_PAGE');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const updateUserDetailsThunk = createAsyncThunk(
  'userPage/updateUserDetailsThunk',
  async (payload: UpdateUserDetailsType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await UserPageAPIs.updateUserDetailsAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'USER_PAGE');
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);

export const updateProfileNumThunk = createAsyncThunk(
  'userPage/updateProfileNumThunk',
  async (payload: UpdateProfileNumType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await UserPageAPIs.updateProfileNumAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'USER_PAGE');
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);
