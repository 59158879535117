/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetUserPageResponseType, UpdateUserDetailsType } from './apis';
import {
  getUserPageThunk,
  updateProfileNumThunk,
  updateUserDetailsThunk,
} from './thunks';

export type UserPageSubscriptionMetaType =
  | Record<string, never>
  | {
      subscriptionID: string;
      basicProfileID: string;
      p_birthdate: string;
      p_gender: string;
      p_years_in_canada: string;
      include_spouse: string;
      s_birthdate: string;
      s_gender: string;
      s_years_in_canada: string;
      expiry_date: string;
      can_renew: boolean;
      is_valid: boolean;
    };

export interface ProfileType {
  profileID: string;
  date: string;
  source: string;
  completed: boolean;
}

export interface UserPageState {
  pending: boolean;
  userDetails: { firstName: string; lastName: string };
  subscriptionDetails: UserPageSubscriptionMetaType;
  profiles: ProfileType[];
  editUserNameModal: {
    isOpen: boolean;
    data?: { firstName: string; lastName: string };
  };
  addProfileNumberModal: {
    isOpen: boolean;
    data?: { firstName: string; lastName: string };
  };
}

const initialState: UserPageState = {
  pending: false,
  userDetails: { firstName: '', lastName: '' },
  subscriptionDetails: {},
  profiles: [],
  editUserNameModal: { isOpen: false, data: { firstName: '', lastName: '' } },
  addProfileNumberModal: { isOpen: false },
};

const slice = createSlice({
  name: 'userPage',
  initialState,
  reducers: {
    openEditUserNameModalAction: (
      state: UserPageState,
      action: PayloadAction<UserPageState['editUserNameModal']['data']>,
    ) => {
      state.editUserNameModal = { isOpen: true, data: action.payload };
    },
    closeEditUserNameModalAction: (state: UserPageState) => {
      state.editUserNameModal = { isOpen: false };
    },
    openAddProfileNumberModalAction: (state: UserPageState) => {
      state.addProfileNumberModal = { isOpen: true };
    },
    closeAddProfileNumberModalAction: (state: UserPageState) => {
      state.addProfileNumberModal = { isOpen: false };
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserPageThunk.pending, (state: UserPageState) => {
      state.pending = true;
    });
    builder.addCase(
      getUserPageThunk.fulfilled,
      (
        state: UserPageState,
        action: PayloadAction<GetUserPageResponseType>,
      ) => {
        state.pending = false;
        state.userDetails = action.payload.user;
        state.subscriptionDetails = action.payload.subscription;
        state.profiles = action.payload.profiles;
      },
    );
    builder.addCase(getUserPageThunk.rejected, (state: UserPageState) => {
      state.pending = false;
      // TODO: HANDLE THIS ERROR
      // console.log('REJECTED', action.payload);
    });
    builder.addCase(updateUserDetailsThunk.pending, (state: UserPageState) => {
      state.pending = true;
    });
    builder.addCase(
      updateUserDetailsThunk.fulfilled,
      (state: UserPageState, action: PayloadAction<UpdateUserDetailsType>) => {
        state.pending = false;
        state.editUserNameModal = { isOpen: false };
        state.userDetails = {
          ...state.userDetails,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        };
      },
    );
    builder.addCase(updateUserDetailsThunk.rejected, (state: UserPageState) => {
      state.pending = false;
      // TODO: HANDLE THIS ERROR
      // console.log('REJECTED', action.payload);
    });
    builder.addCase(updateProfileNumThunk.pending, (state: UserPageState) => {
      state.pending = true;
    });
    builder.addCase(updateProfileNumThunk.fulfilled, (state: UserPageState) => {
      state.pending = false;
      state.addProfileNumberModal = { isOpen: false };
    });
    builder.addCase(updateProfileNumThunk.rejected, (state: UserPageState) => {
      state.pending = false;
    });
  },
});

export const {
  openEditUserNameModalAction,
  closeEditUserNameModalAction,
  openAddProfileNumberModalAction,
  closeAddProfileNumberModalAction,
} = slice.actions;

export default slice.reducer;
