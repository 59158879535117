import React, { ReactNode, memo } from 'react';
import styled from 'styled-components';
import { COLORS, FONTS, FONT_SIZE, NO_SELECT } from '@constants/styles';

const ST = {
  Wrapper: styled.div<{ selectable: boolean; textAlign: string }>`
    width: 100%;
    text-align: ${p => p.textAlign};
    ${p => (p.selectable ? '' : NO_SELECT)}
  `,
  Text: styled.span<{
    color: string;
    fontSize: string;
    fontFamily: string;
  }>`
    width: 100%;
    color: ${p => p.color};
    font-size: ${p => p.fontSize};
    font-family: ${p => p.fontFamily};
  `,
  Link: styled.a<{ color: string }>`
    cursor: pointer;
    font-family: ${FONTS.BOLD};
    color: ${p => p.color};

    :hover {
      color: ${COLORS.BLACK};
    }
  `,
};

export interface RawTextProps {
  text: string;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
  icon?: ReactNode;
  link?: Array<{
    url: string;
    label: string;
    fcn?: () => void;
    color?: string;
  }>;
  break?: boolean;
}
interface Props {
  text: Array<RawTextProps>;
  textAlign?: string;
  selectable?: boolean;
  linkSplitString?: string;
}

const RawText: React.FC<Props> = ({
  text,
  textAlign = 'left',
  selectable = false,
  linkSplitString = '*$*',
}) => {
  const runLinkFunction = (fcn?: () => void) => {
    if (fcn) {
      fcn();
    }
  };
  const openLink = (url: string) => {
    window.open(url, '_blank');
  };

  const parseText = (val: RawTextProps) => {
    const finalText: Array<ReactNode> = [];

    val.text.split(linkSplitString).forEach((d, i) => {
      finalText.push(
        <ST.Text
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          color={val.color || COLORS.BLACK}
          fontSize={val.fontSize || FONT_SIZE.PX14}
          fontFamily={val.fontFamily || FONTS.NORMAL}
        >
          {d}
          {val.icon}
          {val.link && i <= val.link.length - 1 && (
            <ST.Link
              color={val.link[i].color || COLORS.MAIN}
              onClick={() => {
                if (val.link && val.link[i]) {
                  if (val.link[i].fcn !== undefined) {
                    runLinkFunction(val.link[i].fcn);
                  } else {
                    openLink(val.link[i].url);
                  }
                }
              }}
            >
              {val.link[i].label}
            </ST.Link>
          )}
        </ST.Text>,
      );
    });

    return finalText;
  };

  return (
    <ST.Wrapper selectable={selectable} textAlign={textAlign}>
      {text.map((d, i) => (
        <ST.Text
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          color={d.color || COLORS.BLACK}
          fontSize={d.fontSize || FONT_SIZE.PX14}
          fontFamily={d.fontFamily || FONTS.NORMAL}
        >
          {parseText(d)}
          {i !== text.length - 1 && ' '}
          {d.break && <br />}
        </ST.Text>
      ))}
    </ST.Wrapper>
  );
};

export default memo(RawText);
