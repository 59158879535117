import React, { memo } from 'react';
import styled from 'styled-components';
import { FONTS, FONT_SIZE, COLORS, NO_SELECT } from '@constants/styles';

const ST = {
  Wrapper: styled.div<{ height: string; highlight: boolean }>`
    height: ${p => p.height};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-family: ${p => (p.highlight ? FONTS.BOLD : FONTS.NORMAL)};
    font-size: ${FONT_SIZE.PX20};
    color: ${p => (p.highlight ? COLORS.MAIN : COLORS.TEXT)};
    cursor: pointer;
    position: relative;

    ${NO_SELECT}

    :hover {
      color: ${COLORS.ORANGE};
    }
  `,
};

interface Props {
  label: string;
  clickFcn: () => void;
  highlight: boolean;
  height?: string;
}

const RowItem: React.FC<Props> = ({
  label,
  clickFcn,
  height = '100%',
  highlight,
}) => (
  <ST.Wrapper onClick={clickFcn} highlight={highlight} height={height}>
    {label}
  </ST.Wrapper>
);

export default memo(RowItem);
