import { startCase, toLower } from 'lodash';
import { format } from 'd3-format';
import { FormattingParamsType } from './types';

const toTitleCase = (d: string) => startCase(toLower(d));

const formatPercent = (d: number) => format('.1%')(d);
const formatDecimal = (d: number, decimal: number) =>
  format(`0.${decimal}f`)(d);
const formatDollars = (d: number) => format(`$0.2f`)(d);
const formatRoundDollars = (d: number) => format(`$,.0f`)(d);
const formatNumber = (d: number) => format(',')(d);
const formatSignedNumber = (d: number) => format('+,')(d);

const pad = (num: number, count = 2) => num.toString().padStart(count, '0');

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatTime = (val: Date) => {
  const hours = val.getHours();
  const mins = val.getMinutes();
  const secs = val.getSeconds();

  return `${pad(hours)}:${pad(mins)}:${pad(secs)}`;
};

const formatSimpleTime = (val: Date) => {
  const hours = val.getHours();
  const mins = val.getMinutes();

  return `${pad(hours)}:${pad(mins)}`;
};

const formatDate = (temp: string) => {
  const val = new Date(temp);
  const year = val.getFullYear();
  const month = months[val.getMonth()];
  const dayOfMonth = val.getDate();

  return `${month} ${dayOfMonth}, ${pad(year)}`;
};

const formatSQLDate = (val: Date) => {
  const year = val.getFullYear();
  const month = val.getMonth() + 1;
  const dayOfMonth = val.getDate();

  return `${pad(year)}-${pad(month)}-${pad(dayOfMonth)}`;
};

const convertSQLDateToHuman = (val: string) => {
  const splitDate = val.split('-');
  return `${months[+splitDate[1] - 1]} ${+splitDate[2]}, ${+splitDate[0]}`;
};

const formatDateUTC = (temp: string) => {
  const val = new Date(temp);

  const year = val.getUTCFullYear();
  const month = months[val.getUTCMonth()];
  const dayOfMonth = val.getUTCDate();

  return `${month} ${dayOfMonth}, ${pad(year)}`;
};

export const formatByType = ({ type, val }: FormattingParamsType) => {
  if (type === 'text') {
    return val;
  }

  if (
    type !== 'nullable_text' &&
    ([Infinity, -Infinity, null, undefined].includes(val as number) ||
      Number.isNaN(val as number))
  ) {
    if (type === 'reverse_signed_num_na') {
      return '-';
    }
    return 'n/a';
  }

  switch (type) {
    case 'nullable_text':
      return val || '-';
    case 'title':
      return toTitleCase(val);
    case 'dollars':
      return formatDollars(val);
    case 'round_dollars':
      return formatRoundDollars(+val);
    case 'percent':
      return formatPercent(val);
    case 'number':
      return formatNumber(val);
    case 'one_decimal':
      return formatDecimal(val, 1);
    case 'num_dash':
      return +val === 0 ? '-' : formatNumber(+val);
    case 'num_dash_signed':
      return +val === 0 ? '-' : formatSignedNumber(+val);
    case 'signed_num':
      return formatSignedNumber(+val);
    case 'reverse_signed_num_na':
    case 'reverse_signed_num':
      if (+val < 0) {
        return `+${Math.abs(+val)}`;
      }
      return `${val}`;
    case 'pos_neg':
      if (+val < 0) {
        return '-';
      }
      if (+val > 0) {
        return '+';
      }
      return '0';
    case 'date':
      return formatDate(val);
    case 'date_sql':
      return formatSQLDate(val);
    case 'date_utc':
      return formatDateUTC(val);
    case 'date_sql_human':
      if (val === '') return val;
      return convertSQLDateToHuman(val);
    case 'time':
      return formatTime(val);
    case 'simple_time':
      return formatSimpleTime(val);
    case 'rank':
      return ['1st', '2nd', '3rd'][val] || `${val + 1}th`;
    default:
      return val;
  }
};
