import React, { memo } from 'react';
import styled from 'styled-components';

import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';
import MultiButtonRow, { BUTTON_TYPE } from '@components/MultiButtonRow';
import HelpBubble from '../HelpBubble';

const ST = {
  Wrapper: styled.div<{
    selectable: boolean;
    topPadding: string;
    bottomPadding: string;
  }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${p => p.topPadding} 0px ${p => p.bottomPadding} 0px;
    ${p => (p.selectable ? '' : NO_SELECT)}
  `,
  TitleWrapper: styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
    align-items: center;
  `,
  Title: styled.div<{ color: string; fontFamily: string; fontSize: string }>`
    // width: 100%;
    font-size: ${p => p.fontSize};
    font-family: ${p => p.fontFamily};
    color: ${p => p.color};
  `,
  ButtonWrapper: styled.div`
    height: 30px;
  `,
};

export interface CustomTitleProps {
  label: string;
  size?: string;
  color?: string;
  font?: string;
  buttons?: Array<BUTTON_TYPE>;
  frontButtons?: Array<BUTTON_TYPE>;
  helpText?: { key: string; text: string };
  selectable?: boolean;
  topPadding?: string;
  bottomPadding?: string;
}

const CustomTitle: React.FC<CustomTitleProps> = ({
  label,
  size = FONT_SIZE.PX16,
  color = COLORS.BLACK,
  font = FONTS.MEDIUM,
  buttons = [],
  frontButtons = [],
  helpText = undefined,
  selectable = false,
  topPadding = PADDING.MD,
  bottomPadding = PADDING.MD,
}) => (
  <ST.Wrapper
    selectable={selectable}
    topPadding={topPadding}
    bottomPadding={bottomPadding}
  >
    <ST.TitleWrapper>
      <ST.Title color={color} fontFamily={font} fontSize={size}>
        {label}
        {helpText && <HelpBubble helpText={helpText} />}
      </ST.Title>
      {frontButtons.length > 0 && (
        <ST.ButtonWrapper>
          <MultiButtonRow buttons={frontButtons} />
        </ST.ButtonWrapper>
      )}
    </ST.TitleWrapper>
    {buttons.length > 0 && (
      <ST.ButtonWrapper>
        <MultiButtonRow buttons={buttons} />
      </ST.ButtonWrapper>
    )}
  </ST.Wrapper>
);

export default memo(CustomTitle);
