import axios from 'axios';
import { HTTP_API_URL } from '@constants/endpoints';
import { apiErrorProcessing } from '@constants/error_messages';
import { SubscriptionPricingType } from './reducer';

export interface orderSubscriptionType {
  include_spouse: string;
  p_birthdate: string;
  p_gender: string;
  p_years_in_canada: number;
  s_birthdate: string;
  s_gender: string;
  s_years_in_canada: number;
  currDate: string;
  promoCode: string;
  paymentToken: string;
  subscriptionYears: number;
}

export interface orderSubscriptionResponseType {}

export interface getSubscriptionPricingType {}

export interface getSubscriptionPricingResponseType {
  pricing: SubscriptionPricingType;
  subscription: {
    subscribed: boolean;
    data?: {
      include_spouse: string;
      p_birthdate: string;
      p_gender: string;
      p_years_in_canada: number;
      s_birthdate: string;
      s_gender: string;
      s_years_in_canada: number;
    };
  };
}

export default class SubscriptionPageAPIs {
  static orderSubscriptionAPI(token: string, payload: orderSubscriptionType) {
    return axios
      .post(`${HTTP_API_URL}/survey/orderSubscription`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: orderSubscriptionResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static getSubscriptionPricingAPI(
    token: string,
    payload: getSubscriptionPricingType,
  ) {
    return axios
      .post(`${HTTP_API_URL}/survey/getSubscriptionPricing`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        (result: { data: getSubscriptionPricingResponseType }) => result.data,
      )
      .catch(apiErrorProcessing);
  }
}
