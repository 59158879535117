/* eslint-disable indent */
import React, { memo } from 'react';
import styled from 'styled-components';
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from 'react-icons/io';
import { isEqual } from 'lodash';

import { COLORS, FONT_SIZE } from '@constants/styles';
import TableLoader from './components/TableLoader';
import TableCellRenderer from './components/TableCellRenderer';
import TableBodyCell from './components/TableBodyCell';
import TableHeaderCell from './components/TableHeaderCell';
import { AdditionalHeaderType, TableColumnType } from './types';
import TableTooltip from './components/TableTooltip';

const ST = {
  Table: styled.table`
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    display: table;
  `,
  Head: styled.thead`
    text-transform: uppercase;
  `,
  Body: styled.tbody``,
  Row: styled.tr<{
    showHover?: boolean;
    height?: number;
    hideBorder?: boolean;
    borderColor?: string;
  }>`
    width: 100%;
    display: table-row;
    ${p => p.height && `height: ${p.height}px;`}
    ${p => !p.hideBorder && `border-bottom: 0.25px solid ${p.borderColor};`}
    ${p =>
      p.showHover &&
      `
        cursor: pointer;
        :hover, :hover td {
          background-color: ${COLORS.LIGHT_GRAY};
        }
    `}
  `,
};

interface Props {
  data: Array<any>;
  cols: Array<TableColumnType>;
  sortKey?: { key: string; ascending: boolean };
  updateSortKey?: (a: { key: string; ascending: boolean }) => void;
  rowClicked?: (row: any) => void;
  showRowHover?: boolean;
  rowHeight?: number;
  isLoading?: boolean;
  showHeaderBorder?: boolean;
  additionalHeader?: Array<AdditionalHeaderType>;
  headerFontSize?: string;
  hideHeader?: boolean;
  borderColor?: string;
  hideRowBorder?: Array<number>;
}

const CustomTable: React.FC<Props> = ({
  data,
  cols,
  sortKey = { key: '', ascending: true },
  updateSortKey = () => {},
  rowClicked = () => {},
  showRowHover = false,
  rowHeight = undefined,
  isLoading = false,
  showHeaderBorder = true,
  additionalHeader = [],
  headerFontSize = FONT_SIZE.PX12,
  hideHeader = false,
  borderColor = COLORS.TABLE_GRAY,
  hideRowBorder = [],
}) => {
  const sortColumn = (val: string) => {
    updateSortKey({
      key: val,
      ascending: val === sortKey.key ? !sortKey.ascending : true,
    });
  };

  const sortArrow = sortKey.ascending ? (
    <IoMdArrowRoundUp />
  ) : (
    <IoMdArrowRoundDown />
  );

  const fixedColumns = cols.filter(d => d.fixed);

  return (
    <ST.Table>
      {!hideHeader && (
        <ST.Head>
          {additionalHeader.length > 0 && (
            <ST.Row hideBorder>
              {additionalHeader.map((d: AdditionalHeaderType, i: number) => {
                const details = cols[i];
                return (
                  !d.skip && (
                    <TableHeaderCell
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      colSpan={d.colSpan || 1}
                      colWidth={d.width || details.colWidth}
                      colNumber={i}
                      fixed={details.fixed}
                      textAlign={details.headerTextAlign}
                      fontSize={headerFontSize}
                    >
                      {d?.text || ''}
                    </TableHeaderCell>
                  )
                );
              })}
            </ST.Row>
          )}
          <ST.Row hideBorder={!showHeaderBorder} borderColor={borderColor}>
            {cols.map((d: TableColumnType, i: number) => (
              <TableHeaderCell
                key={d.key}
                colWidth={d.colWidth}
                colNumber={i}
                fixed={d.fixed}
                clickFcn={() => {
                  sortColumn(d.sortKey || d.key);
                }}
                fontSize={headerFontSize}
                textAlign={d.headerTextAlign}
                sortableColumn={sortKey.key !== ''}
              >
                {d.label}
                {(d.sortKey || d.key) === sortKey.key && sortArrow}
              </TableHeaderCell>
            ))}
          </ST.Row>
        </ST.Head>
      )}
      <ST.Body>
        {data.map((d, i) => (
          <ST.Row
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            onClick={() => {
              rowClicked(d);
            }}
            showHover={showRowHover}
            height={rowHeight}
            hideBorder={hideRowBorder.includes(i) || i === data.length - 1}
            borderColor={borderColor}
          >
            {cols.map((g, j: number) => (
              <TableBodyCell
                key={g.key}
                fontSize={g.fontSize}
                baseColor={g.baseColor}
                fontFamily={g.fontFamily}
                fixed={g.fixed}
                colNumber={j}
                colWidth={g.colWidth}
                numFixedColumns={fixedColumns.length}
                highlight={g.highlight && g.highlight(d)}
                highlightBkg={g.highlightBkg}
                highlightColor={g.highlightColor}
                textAlign={g.textAlign}
                horizontalPadding={g.horizontalPadding}
                verticalPadding={g.verticalPadding}
                helpAnchor={g.helpText && `tbl_tt_${g.key}~${i}`}
                helpText={g.helpText && g.helpText(d)}
              >
                <TableCellRenderer type={g.type} val={d[g.key]} />
                {g.helpText && (
                  <TableTooltip tooltipKey={`tbl_tt_${g.key}~${i}`} />
                )}
              </TableBodyCell>
            ))}
          </ST.Row>
        ))}
        {isLoading && <TableLoader colSpan={cols.length} />}
      </ST.Body>
    </ST.Table>
  );
};

const arePropsEqual = (p: Props, n: Props) =>
  isEqual(p.cols, n.cols) &&
  isEqual(p.data, n.data) &&
  isEqual(p.showRowHover, n.showRowHover) &&
  isEqual(p.sortKey, n.sortKey) &&
  isEqual(p.showRowHover, n.showRowHover) &&
  isEqual(p.rowHeight, n.rowHeight) &&
  isEqual(p.isLoading, n.isLoading) &&
  isEqual(p.showHeaderBorder, n.showHeaderBorder) &&
  isEqual(p.additionalHeader, n.additionalHeader);

export default memo(CustomTable, arePropsEqual);
