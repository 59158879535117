/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { memo } from 'react';
import styled from 'styled-components';
import MultiButtonRow, {
  ButtonStylesType,
  BUTTON_TYPE,
} from '@components/MultiButtonRow';

const ST = {
  Wrapper: styled.div`
    // height: 100%;
    // width: 100%;
    width: 200px;
    height: 50px;

    input[type='file'] {
      display: none;
    }
  `,
};

export interface FileType {
  file: any;
  url: string;
}

interface Props {
  keyID: string;
  updateValue: (a: File | null) => void;
  buttonText?: string;
  btnStyle?: ButtonStylesType;
  customStyles?: BUTTON_TYPE['custom'];
}

const CustomFileInput: React.FC<Props> = ({
  keyID,
  updateValue,
  buttonText = 'Choose File',
  btnStyle = 'MAIN',
  customStyles = {},
}) => {
  const fileChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      updateValue(ev.target.files[0]);
    } else {
      updateValue(null);
    }
  };

  const randomKey = `${keyID}_${Math.floor(Math.random() * 1000)}`;

  return (
    <ST.Wrapper>
      <label htmlFor={`${randomKey}`}>
        <MultiButtonRow
          buttons={[
            {
              key: `${randomKey}_button`,
              text: buttonText,
              fcn: () => {},
              style: btnStyle,
              custom: customStyles,
            },
          ]}
        />
      </label>
      <input id={`${randomKey}`} type={'file'} onChange={fileChanged} />
    </ST.Wrapper>
  );
};

export default memo(CustomFileInput);
