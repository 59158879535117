import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { processThunkError } from '@utils/processThunkError';

import {
  setLoggedInAction,
  setAutoLoginAction,
  setPending,
  attemptForceLoginAction,
} from './reducer';
import GlobalAPIs, { getGlobalUsernameType } from './apis';

export const attemptSignoutThunk = createAsyncThunk(
  'global/attemptSignout',
  async (payload, { dispatch }) => {
    dispatch(setPending(true));
    try {
      await Auth.signOut();
    } catch (error) {
      dispatch(setPending(false));
      dispatch(setLoggedInAction(false));
      return;
    }
    dispatch(setPending(false));
  },
);

export const attemptAutoLoginThunk = createAsyncThunk(
  'global/attemptAutoLogin',
  async (payload: string, { dispatch }) => {
    dispatch(setPending(true));
    const token = await GlobalAPIs.getTokenAPI();
    if (token !== null) {
      dispatch(setAutoLoginAction(payload));
    }

    dispatch(setPending(false));
  },
);

export const genericTokenCheck = async (dispatch: any, bypass = false) => {
  const token = await GlobalAPIs.getTokenAPI();
  if (token === null) {
    if (!bypass) {
      dispatch(attemptForceLoginAction());
    }

    // eslint-disable-next-line no-throw-literal
    throw 'INVALID_TOKEN';
  }
  return token;
};

export const getGlobalUsernameThunk = createAsyncThunk(
  'global/getGlobalUsername',
  async (payload: getGlobalUsernameType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await GlobalAPIs.getGlobalUsernameAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'GLOBAL',
        true,
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
