import React, { memo } from 'react';
import styled from 'styled-components';
import HashLoader from 'react-spinners/HashLoader';

import { COLORS, ZINDEX } from '@constants/styles';

const ST = {
  Wrapper: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: ${ZINDEX.MAX};
  `,
  Shadow: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.BLACK};
    opacity: 0.4;
  `,
  Container: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
};

interface Props {
  color?: string;
}

const PendingScreen: React.FC<Props> = ({ color = COLORS.MAIN }) => (
  <ST.Wrapper>
    <ST.Shadow />
    <ST.Container>
      <HashLoader color={color} size={70} speedMultiplier={1} />
    </ST.Container>
  </ST.Wrapper>
);

export default memo(PendingScreen);
