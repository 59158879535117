import { createAsyncThunk } from '@reduxjs/toolkit';
import { processThunkError } from '@utils/processThunkError';
import { genericTokenCheck } from '@containers/App/state/thunks';
import { RootState } from '@app/store';

import SurveyPageAPIs, {
  orderReportType,
  getCompletedReportType,
  deletePERCReportType,
  downloadPERCReportType,
  checkSubscriptionStatusType,
} from './apis';
import { selectLocalPending } from './selectors';

export const orderReportThunk = createAsyncThunk(
  'surveyPage/orderReport',
  async (payload: orderReportType, { dispatch, rejectWithValue, getState }) => {
    let token = null;

    try {
      token = await genericTokenCheck(dispatch, true);
    } catch {
      //
    }

    let results;

    let currentlyPending = false;
    try {
      results = await SurveyPageAPIs.orderReportAPI(token, payload);
      const tempState = getState() as RootState;
      currentlyPending = selectLocalPending(tempState);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'SURVEY_PAGE');
      return rejectWithValue(errorReject);
    }

    return { ...results, currentlyPending };
  },
);

export interface getCompletedReportThunkPayloadType {
  payload: getCompletedReportType;
  refreshParams: { startPage: number | null; isNew?: boolean };
}

export const getCompletedReportThunk = createAsyncThunk(
  'surveyPage/getCompletedReport',
  async (
    payload: getCompletedReportThunkPayloadType,
    { dispatch, rejectWithValue },
  ) => {
    let token = null;

    try {
      token = await genericTokenCheck(dispatch, true);
    } catch {
      //
    }
    let results;
    try {
      results = await SurveyPageAPIs.getCompletedReport(token, payload.payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'SURVEY_PAGE');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const checkSubscriptionStatusThunk = createAsyncThunk(
  'surveyPage/checkSubscriptionStatus',
  async (
    payload: checkSubscriptionStatusType,
    { dispatch, rejectWithValue },
  ) => {
    let token = null;

    try {
      token = await genericTokenCheck(dispatch, true);
    } catch {
      //
    }

    if (token) {
      let results;
      try {
        results = await SurveyPageAPIs.checkSubscriptionStatusAPI(
          token,
          payload,
        );
      } catch (err: any) {
        const errorReject = await processThunkError(
          err,
          dispatch,
          'SURVEY_PAGE',
          true,
        );
        return rejectWithValue(errorReject);
      }
      return results;
    }
    return rejectWithValue('NOT_SUBSCRIBED');
  },
);

export const deletePERCReportThunk = createAsyncThunk(
  'surveyPage/deletePERCReport',
  async (payload: deletePERCReportType, { dispatch, rejectWithValue }) => {
    let results;
    let token = null;

    try {
      token = await genericTokenCheck(dispatch);
      results = await SurveyPageAPIs.deletePERCReportAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'SURVEY_PAGE');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const downloadPERCReportThunk = createAsyncThunk(
  'surveyPage/downloadPERCReport',
  async (payload: downloadPERCReportType, { dispatch, rejectWithValue }) => {
    let results;
    let token = null;

    try {
      token = await genericTokenCheck(dispatch);
      results = await SurveyPageAPIs.downloadPERCReportAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'SURVEY_PAGE');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
