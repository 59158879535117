import React from 'react';
import styled from 'styled-components';
import { COLORS, FONT_SIZE } from '@constants/styles';
import SectionText from '@components/PageBuilder/components/SectionText';
import TextSpacer from '@components/PageBuilder/components/TextSpacer';
import { SurveyPageType } from './surveyPages';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  `,
  Span: styled.span``,
  Section: styled.div`
    width: 100%;
    padding: 10px 0px;
  `,
  List: styled.ol`
    counter-reset: list;
    margin: 0px;

    li {
      list-style: none;
    }

    li:before {
      content: counter(list) ') ';
      counter-increment: list;
    }
  `,
  ListItem: styled.li``,
};

export const RESULT_DESCRIPTION_PAGE_CUSTOM: SurveyPageType =
  // MARK: Results Description Page
  {
    key: 'results_description',
    pageType: 'standard',
    title: 'Your Results',
    questions: () => [
      {
        key: 'book_image',
        type: 'image_text_row',
        image: 'images/book_image.jpg',
        text: (
          <ST.Wrapper>
            <ST.Section>
              <SectionText
                text={`Thanks for your input. PERC is now running and will show you results in a moment.`}
                color={COLORS.BLACK}
                fontSize={FONT_SIZE.PX14}
              />
              <TextSpacer />
              {/* <SectionText
                text={`Please note that the investment returns assumed in the projections are based on a 60-40 (equity-bond) asset mix that achieves 10th percentile returns. This means very poor returns are being assumed. This is done to improve the odds that the income projections are safe.`}
                color={COLORS.BLACK}
                fontSize={FONT_SIZE.PX14}
              />
              <TextSpacer /> */}
              <SectionText
                text={`To get the most out of this exercise, users are encouraged to read "Retirement Income for Life" by Frederick Vettese.`}
                color={COLORS.BLACK}
                fontSize={FONT_SIZE.PX14}
              />
              <TextSpacer />
            </ST.Section>
          </ST.Wrapper>
        ),
        props: {
          textBackgroundColor: COLORS.TRANSPARENT,
          leftText: true,
        },
      },
    ],
    validation: () => ({}),
    widthOverride: 1000,
  };
