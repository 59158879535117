import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks';
import { selectCollapse } from '@containers/App/state/selectors';
import { COLORS, PADDING, FONTS, NO_SELECT } from '@constants/styles';
import { TextAlignTypes } from '@constants/commonTypes';

const ST = {
  Wrapper: styled.th<{
    fontSize: string;
    colWidth?: number;
    left?: number;
    fixed?: boolean;
    textAlign?: TextAlignTypes;
    sortableColumn: boolean;
  }>`
    ${p => (p.colWidth !== undefined ? `width: ${p.colWidth}px;` : '')}
    padding: ${PADDING.SM};
    color: ${COLORS.BLACK};
    text-align: ${p => p.textAlign || 'right'} !important;
    font-family: ${FONTS.BOLD};
    font-size: ${p => p.fontSize};
    background-color: ${COLORS.WHITE};

    position: sticky;
    top: 0;
    left: ${p => p.left}px;
    z-index: ${p => (p.fixed ? 4 : 3)};

    ${NO_SELECT}

    ${p =>
      p.sortableColumn
        ? `
          cursor: pointer;
          :hover {
            color: ${COLORS.TEXT_HIGHLIGHT};
          }
        `
        : ''}
  `,
};

interface Props {
  colNumber: number;
  fontSize: string;
  children: ReactNode;
  fixed?: boolean;
  textAlign?: TextAlignTypes;
  colWidth?: number;
  sortableColumn?: boolean;
  clickFcn?: () => void;
  colSpan?: number;
  helpAnchor?: string;
}

const TableCell: React.FC<Props> = ({
  colNumber,
  fontSize,
  children,
  colWidth,
  fixed = false,
  textAlign = 'right',
  sortableColumn = false,
  clickFcn = undefined,
  colSpan = 1,
  helpAnchor = '',
}) => {
  const collapse = useAppSelector(selectCollapse);

  return (
    <ST.Wrapper
      id={helpAnchor}
      fontSize={fontSize}
      fixed={fixed}
      left={
        fixed && colWidth !== undefined && !collapse
          ? colWidth * colNumber
          : undefined
      }
      colWidth={colWidth}
      textAlign={textAlign}
      sortableColumn={sortableColumn}
      onClick={clickFcn}
      colSpan={colSpan}
    >
      {children}
    </ST.Wrapper>
  );
};
export default memo(TableCell);
