import React, { memo } from 'react';
import { IoIosHelpCircle } from 'react-icons/io';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { COLORS, PADDING, ZINDEX } from '@constants/styles';
// import HelpPopup from '../HelpPopup';

const ST = {
  Wrapper: styled.span<{ tooltipKey: string }>`
    cursor: help;
    margin-left: ${PADDING.XS};
    color: ${COLORS.MAIN};

    .custom-tooltip {
      text-align: center !important;
      max-width: 200px;
      z-index: ${ZINDEX.HIGH};
      opacity: 1;
    }

    svg {
      transform: translate(0, 4px);
    }
  `,
};

interface Props {
  helpText: { key: string; text: string };
}

const HelpBubble: React.FC<Props> = ({ helpText }) => (
  <ST.Wrapper tooltipKey={helpText.key}>
    <IoIosHelpCircle id={helpText.key} size={20} />
    <Tooltip
      className={'custom-tooltip'}
      anchorId={helpText.key}
      html={helpText.text}
      place={'top'}
    />
  </ST.Wrapper>
);

export default memo(HelpBubble);
