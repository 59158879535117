/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getNewProfileIDThunk } from './thunks';

export interface ReportRedirectPageState {
  pending: boolean;
}

const initialState: ReportRedirectPageState = {
  pending: false,
};

const slice = createSlice({
  name: 'reportRedirectPage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getNewProfileIDThunk.pending,
      (state: ReportRedirectPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getNewProfileIDThunk.fulfilled,
      (state: ReportRedirectPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      getNewProfileIDThunk.rejected,
      (state: ReportRedirectPageState) => {
        state.pending = false;
      },
    );
  },
});

// export const {} = slice.actions;

export default slice.reducer;
