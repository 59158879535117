import { useEffect } from 'react';
import { isEqual } from 'lodash-es';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Amplify, Hub } from 'aws-amplify';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { setLoggedInAction } from '@containers/App/state/reducer';
import { attemptAutoLoginThunk } from '@containers/App/state/thunks';
import {
  selectLoggedIn,
  selectLoginRedirect,
  selectSignoutTime,
} from '@containers/App/state/selectors';
import usePrevious from '@customHooks/usePrevious';

const {
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_AUTH_FLOW,
  REACT_APP_AWS_CLIENT_ID,
} = process.env;

// console.log('AUTH', process.env);

Amplify.configure({
  Auth: {
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    region: REACT_APP_AWS_REGION,
    authenticationFlowType: REACT_APP_AWS_AUTH_FLOW,
    userPoolWebClientId: REACT_APP_AWS_CLIENT_ID,
  },
});

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const signoutTime = useAppSelector(selectSignoutTime);
  const loginRedirect = useAppSelector(selectLoginRedirect);
  const loggedIn = useAppSelector(selectLoggedIn);

  const prevSignoutTime = usePrevious(signoutTime);

  const onAuthChanged = ({
    payload: { event },
  }: {
    payload: { event: string };
  }) => {
    switch (event) {
      case 'signIn':
        dispatch(setLoggedInAction(true));
        break;
      case 'signOut':
        dispatch(setLoggedInAction(false));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isEqual(prevSignoutTime, signoutTime) && signoutTime !== null) {
      navigate(
        location.pathname === '/auth/login'
          ? '/auth/login'
          : `/auth/login?redirect=${location.pathname}`,
      );
    }
  }, [prevSignoutTime, signoutTime]);

  const prevLoggedIn = usePrevious(loggedIn);

  useEffect(() => {
    if (!isEqual(prevLoggedIn, loggedIn)) {
      if (!prevLoggedIn && loggedIn) {
        navigate(loginRedirect || searchParams.get('redirect') || '/account');
      } else if (prevLoggedIn && !loggedIn) {
        navigate('/');
      }
    }
  }, [prevLoggedIn, loggedIn]);

  useEffect(() => {
    Hub.listen('auth', onAuthChanged);
    dispatch(attemptAutoLoginThunk(`${location.pathname}${location.hash}`));
  }, []);
};
