import axios from 'axios';
import { HTTP_API_URL } from '@constants/endpoints';
import { apiErrorProcessing } from '@constants/error_messages';

export interface LoginPayloadType {
  email: string;
  password: string;
}

export interface ResendVerificationPayloadType {
  email: string;
}

export interface ConfirmVerificationPayloadType {
  email: string;
  code: string;
}

export interface RegisterPayloadType {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface ForgotPasswordPayloadType {
  email: string;
}

export interface ResetPasswordPayloadType {
  email: string;
  verificationCode: string;
  password: string;
}

interface RegisterAPIPayloadType {
  email: string;
  firstName: string;
  lastName: string;
  subID: string;
}

export default class AuthPagesAPIs {
  static registerAPI(payload: RegisterAPIPayloadType) {
    return axios
      .post(`${HTTP_API_URL}/auth/register`, payload, {})
      .then(() => true)
      .catch(apiErrorProcessing);
  }
}
