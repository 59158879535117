import React, { memo, useState } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { COLORS, FONT_SIZE, PADDING, ZINDEX } from '@constants/styles';
import { CALENDAR_ICON } from '@constants/icons';
import { formatByType } from '@utils/formatting';
import InputWrapper from '../InputWrapper';

const ST = {
  Wrapper: styled.div`
    width: 100%;
  `,
  Container: styled.div`
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0px;
      outline: 0px;
    }

    .MuiOutlinedInput-notchedOutline:focus {
      border: 0px;
      outline: 0px;
    }

    .Mui-error .MuiOutlinedInput-notchedOutline {
      border: 0px;
      outline: 0px;
    }
  `,
};

interface Props {
  value: string | null;
  onChange: (a: string | null) => void;
  disableOpenPicker?: boolean;
  fontSize?: string;
  dateFormat?: string;
  hideIcon?: boolean;
  minDate?: string;
  maxDate?: string;
  openToView?: 'day' | 'month' | 'year';
  disabled?: boolean;
}

const CustomDatePicker: React.FC<Props> = ({
  value,
  onChange,
  disableOpenPicker = false,
  fontSize = FONT_SIZE.PX16,
  dateFormat = 'MMM dd, yyyy',
  hideIcon = false,
  minDate = 'Jan 1, 1900',
  maxDate = new Date().toString(),
  openToView = 'year',
  disabled = false,
}) => {
  const [isOpen, updateIsOpen] = useState(false);
  const dateUpdated = (val: Date | null) => {
    if (val) {
      onChange(`${formatByType({ type: 'date_sql', val })}`);
    } else {
      onChange(null);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.MAIN,
      },
      secondary: {
        main: COLORS.BRIGHT_GREEN,
      },
    },
  });

  return (
    <InputWrapper trailingIcon={!hideIcon && CALENDAR_ICON}>
      <ST.Container>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              open={isOpen}
              onOpen={() => updateIsOpen(true)}
              onClose={() => updateIsOpen(false)}
              value={new Date(`${value}T12:00`)}
              onChange={dateUpdated}
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              openTo={value ? 'day' : openToView}
              disabled={disabled}
              renderInput={(params: any) => {
                const tempParams = params;
                if (!('inputProps' in tempParams)) {
                  tempParams.inputProps = { style: {} };
                }
                if (!('style' in tempParams.inputProps)) {
                  tempParams.inputProps.style = {};
                }

                tempParams.inputProps = {
                  ...tempParams.inputProps,
                  style: {
                    ...tempParams.style,
                    textAlign: 'end',
                    width: '100%',
                    color: COLORS.BLACK,
                    cursor: 'pointer',
                    fontSize,
                    paddingTop: PADDING.MD,
                    paddingBottom: PADDING.MD,
                    borderWidth: '2px',
                  },
                  readOnly: true,
                  placeholder: 'Choose Date',
                };

                return (
                  <TextField
                    {...tempParams}
                    onClick={() => {
                      updateIsOpen(true);
                    }}
                  />
                );
              }}
              PopperProps={{
                sx: { zIndex: ZINDEX.EXTRA_HIGH },
              }}
              disableOpenPicker={disableOpenPicker}
              inputFormat={dateFormat}
              disableMaskedInput
            />
          </LocalizationProvider>
        </ThemeProvider>
      </ST.Container>
    </InputWrapper>
  );
};

export default memo(CustomDatePicker);
