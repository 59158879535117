/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import Select, { CSSObjectWithLabel } from 'react-select';

import { COLORS, FONTS, FONT_SIZE, ZINDEX } from '@constants/styles';
import { TextAlignTypes } from '@constants/commonTypes';
import { DropdownOptionType } from '../types';

interface Props {
  options: Array<DropdownOptionType>;
  selected: DropdownOptionType;
  updateSelected: (a: DropdownOptionType) => void;
  isSearchable?: boolean;
  textAlign?: TextAlignTypes;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
}

const SingleSelectDropdown: React.FC<Props> = ({
  options,
  selected,
  updateSelected,
  isSearchable = false,
  textAlign = 'inherit',
  color = COLORS.BLACK,
  fontSize = FONT_SIZE.PX16,
  fontFamily = FONTS.NORMAL,
}) => {
  const selectionChanged = (val: any) => {
    updateSelected(val);
  };

  return (
    <Select
      options={options}
      hideSelectedOptions={false}
      onChange={selectionChanged}
      value={selected}
      isClearable={false}
      isSearchable={isSearchable}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: base =>
          ({ ...base, zIndex: ZINDEX.EXTRA_HIGH } as CSSObjectWithLabel),
        option: styles =>
          ({
            ...styles,
            cursor: 'pointer',
            textAlign,
          } as CSSObjectWithLabel),
        control: styles =>
          ({
            ...styles,
            cursor: 'pointer',
            minHeight: '48px',
            border: `1px solid ${COLORS.BORDER_GRAY}`,
            textAlign,
          } as CSSObjectWithLabel),
        indicatorSeparator: styles =>
          ({
            ...styles,
            backgroundColor: COLORS.BORDER_GRAY,
          } as CSSObjectWithLabel),
        dropdownIndicator: styles =>
          ({
            ...styles,
            color: COLORS.BORDER_GRAY,
          } as CSSObjectWithLabel),
        singleValue: styles =>
          ({
            ...styles,
            fontFamily,
            fontSize,
            color,
          } as CSSObjectWithLabel),
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: COLORS.LIGHT_GRAY,
          primary: COLORS.MAIN,
        },
      })}
    />
  );
};

export default memo(SingleSelectDropdown);
