import React, { memo } from 'react';
import styled from 'styled-components';

import CheckboxRow from '../CheckboxRow';

const ST = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
};

interface Props {
  data: { key: string; label: string };
  selected: string;
  optionChanged: (a: string) => void;
}

const SingleCheckbox: React.FC<Props> = ({ data, selected, optionChanged }) => {
  const updateCheckboxSelection = (val: string) => {
    optionChanged(selected === val ? '' : val);
  };

  return (
    <ST.Wrapper>
      <CheckboxRow
        key={data.key}
        checkKey={data.key}
        updateCheck={updateCheckboxSelection}
        checked={selected === data.key}
        label={data.label}
      />
    </ST.Wrapper>
  );
};

export default memo(SingleCheckbox);
