import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Routes, Route, useLocation } from 'react-router-dom';
import useSize from '@react-hook/size';

import PendingScreen from '@components/PendingScreen';
import Drawer from '@components/Drawer';
import DialogModal from '@components/DialogModal';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { COLORS, SHADOW_GENERATOR, ZINDEX } from '@constants/styles';
import useAmplifyAuthentication from '@containers/AuthPages/customHooks/useAmplifyAuthentication';

import HomePage from '@containers/HomePage/Loadable';
import LoginPage from '@containers/AuthPages/LoginPage/Loadable';
import RegisterPage from '@containers/AuthPages/RegisterPage/Loadable';
import ForgotPasswordPage from '@containers/AuthPages/ForgotPasswordPage/Loadable';
import ResetPasswordPage from '@containers/AuthPages/ResetPasswordPage/Loadable';
import VerifyEmailPage from '@containers/AuthPages/VerifyEmailPage';

import AboutPage from '@containers/AboutPage/Loadable';
import TipsPage from '@containers/TipsPage/Loadable';
import BasicVsCustomPage from '@containers/BasicVsCustomPage/Loadable';
import PercPage from '@containers/PercPage/Loadable';
import ReportRedirectPage from '@containers/ReportRedirectPage/Loadable';
import SurveyPage from '@containers/SurveyPage/Loadable';
import ContactPage from '@containers/ContactPage/Loadable';
import DownloadPage from '@containers/DownloadPage/Loadable';
import SubscriptionPage from '@containers/SubscriptionPage/Loadable';
import UserPage from '@containers/UserPage/Loadable';
import RedirectPage from '@containers/RedirectPage/Loadable';
import NotFoundPage from '@containers/NotFoundPage/Loadable';
// import TermsAndConditions from '@containers/TermsAndConditions/Loadable';

import {
  setCollapseAction,
  setDrawerAction,
  setErrorModalAction,
} from './state/reducer';
import {
  selectErrorModal,
  selectPending,
  selectShowDrawer,
} from './state/selectors';

const ST = {
  Wrapper: styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    background-color: ${COLORS.WHITE};
  `,
  Body: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  `,
  DrawerWrapper: styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: ${ZINDEX.EXTRA_HIGH + 1};
  `,
  DrawerContainer: styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 300px;
    ${SHADOW_GENERATOR(-1, 0, 2)}
  `,
  ErrorModal: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: ${ZINDEX.MAX};
  `,
};

const App = () => {
  const location = useLocation();
  const bodyRef = useRef(null);

  useAmplifyAuthentication();

  const dispatch = useAppDispatch();
  const showDrawer = useAppSelector(selectShowDrawer);
  const pending = useAppSelector(selectPending);
  const errorModal = useAppSelector(selectErrorModal);

  const closeDrawer = () => {
    dispatch(setDrawerAction(false));
  };
  const [width] = useSize(bodyRef);
  const collapse = width < 700;

  const closeErrorModal = () => {
    dispatch(setErrorModalAction({ isOpen: false, title: '', message: '' }));
  };

  const locationPath = location.pathname;
  useEffect(() => {
    closeErrorModal();
  }, [locationPath]);

  useEffect(() => {
    dispatch(setCollapseAction(collapse));
  }, [collapse]);

  return (
    <ST.Wrapper>
      <ST.Body ref={bodyRef}>
        <Routes>
          <Route path={'/'} element={<HomePage />} />
          <Route path={'/auth/login'} element={<LoginPage />} />
          <Route path={'/auth/register'} element={<RegisterPage />} />
          <Route path={'/auth/verify'} element={<VerifyEmailPage />} />
          <Route path={'/auth/forgot'} element={<ForgotPasswordPage />} />
          <Route path={'/auth/reset'} element={<ResetPasswordPage />} />
          <Route path={'/about'} element={<AboutPage />} />
          <Route path={'/faqs'} element={<TipsPage />} />
          <Route path={'/tips'} element={<TipsPage />} />
          <Route path={'/compare'} element={<BasicVsCustomPage />} />
          <Route path={'/perc'} element={<PercPage />} />
          <Route path={'/contact'} element={<ContactPage />} />
          <Route path={'/basic/:profileID'} element={<ReportRedirectPage />} />
          <Route path={'/custom/:profileID'} element={<ReportRedirectPage />} />
          <Route path={'/profile'} element={<SurveyPage />} />
          <Route path={'/profile/:profileID'} element={<SurveyPage />} />
          <Route path={'/subscription'} element={<SubscriptionPage />} />
          <Route path={'/user'} element={<UserPage />} />
          <Route path={'/account'} element={<UserPage />} />
          <Route path={'/admin/download'} element={<DownloadPage />} />
          <Route path={'/notfound'} element={<NotFoundPage />} />
          {/* <Route path={'/terms'} element={<TermsAndConditions />} /> */}
          <Route path={'*'} element={<RedirectPage />} />
        </Routes>
      </ST.Body>
      {showDrawer && collapse && (
        <ST.DrawerWrapper onClick={closeDrawer}>
          <ST.DrawerContainer>
            <Drawer closeDrawer={closeDrawer} />
          </ST.DrawerContainer>
        </ST.DrawerWrapper>
      )}
      {errorModal.isOpen && (
        <ST.ErrorModal>
          <DialogModal
            title={errorModal.title}
            message={errorModal.message}
            primaryFcn={closeErrorModal}
            primaryText={'OK'}
          />
        </ST.ErrorModal>
      )}
      {pending && <PendingScreen color={COLORS.MAIN} />}
    </ST.Wrapper>
  );
};

export default memo(App);
