import React, { memo } from 'react';
import styled from 'styled-components';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';

import { COLORS } from '@constants/styles';
import CustomNumberInput from '../CustomNumberInput';

const ST = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    color: ${COLORS.TEXT};
  `,
  Spacer: styled.div`
    height: 100%;
    width: 4px;
  `,
  Arrow: styled.div`
    cursor: pointer;
    color: ${COLORS.MAIN};
    display: flex;
    flex-direction: row;
    align-items: center;

    :hover {
      color: ${COLORS.BLACK};
    }
  `,
  Number: styled.div`
    width: 100px;
    // min-width: 50px;
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
  `,
};

interface Props {
  value: number;
  updateValue: (a?: number) => void;
  min?: number;
  max?: number;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
  disableInput?: boolean;
}

const CustomNumArrows: React.FC<Props> = ({
  value,
  updateValue,
  min = 0,
  max = 999,
  prefix,
  suffix,
  disabled = false,
  disableInput = false,
}) => {
  const decrease = () => {
    updateValue(Math.max(value - 1, min));
  };

  const increase = () => {
    updateValue(Math.min(value + 1, max));
  };

  return (
    <ST.Wrapper>
      <ST.Arrow onClick={decrease}>
        <BiMinusCircle size={30} />
      </ST.Arrow>
      <ST.Spacer />
      <ST.Number>
        <CustomNumberInput
          value={value as number}
          onChange={updateValue}
          prefix={prefix}
          suffix={suffix}
          textAlign={'center'}
          disabled={disabled || disableInput}
          min={min}
          decimalScale={0}
        />
      </ST.Number>
      <ST.Spacer />
      <ST.Arrow onClick={increase}>
        <BiPlusCircle size={30} />
      </ST.Arrow>
    </ST.Wrapper>
  );
};

export default memo(CustomNumArrows);
