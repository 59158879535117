import React, { memo, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { animateScroll, Events as scrollEvents } from 'react-scroll';
import { OVERFLOW_STYLE } from '@constants/styles';

const ST = {
  Wrapper: styled.div<{ autoscroll: boolean }>`
    height: 100%;
    width: 100%;
    // overscroll-behavior: contain;

    ${p => OVERFLOW_STYLE(true, true, !p.autoscroll)}
  `,
};

interface Props {
  children: (a: number, b: number) => ReactNode;
  autoscroll?: boolean;
  dataLength: number;
  scrollID?: string;
  scrollAmount?: number;
  scrollDuration?: number;
  buffer?: number; // number of screens worth of data to have on either side
  useInternalRef?: boolean;
}

const LazyScrollableTable = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      autoscroll = false,
      scrollID,
      dataLength,
      scrollAmount = 40,
      scrollDuration = 2000,
      buffer = 2,
      useInternalRef = false,
    },
    ref,
  ) => {
    const internalRef = useRef(null);
    const autoscrollRef = useRef(false);
    const autoscrollAmountRef = useRef(1);
    const autoscrollRepeatAmountRef = useRef(0);

    const [arrIndices, updateArrayIndices] = useState({ start: 0, end: 50 });

    useEffect(() => {
      const autoscrollDiv = () => {
        try {
          if (ref || internalRef) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { scrollTop } = (useInternalRef ? internalRef : ref).current;

            if (autoscrollAmountRef.current === scrollTop) {
              if (autoscrollRepeatAmountRef.current + 1 > 2) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                (useInternalRef ? internalRef : ref).current.scrollTop = 0;
                autoscrollRepeatAmountRef.current = 0;
              } else {
                autoscrollRepeatAmountRef.current += 1;
              }
            }
            autoscrollAmountRef.current = scrollTop;

            if (autoscrollRef.current) {
              animateScroll.scrollMore(scrollAmount, {
                containerId: scrollID,
                duration: scrollDuration,
                smooth: 'linear',
              });
            }
          }
        } catch {
          //
        }
      };

      if (autoscroll && scrollID) {
        autoscrollRef.current = true;
        autoscrollDiv();
        scrollEvents.scrollEvent.register('end', () => {
          autoscrollDiv();
        });
      } else {
        autoscrollRef.current = false;
        scrollEvents.scrollEvent.remove('begin');
        scrollEvents.scrollEvent.remove('end');
      }
    }, [autoscroll]);

    const checkData = (
      scrollHeight: number,
      clientHeight: number,
      scrollTop: number,
    ) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const row = (useInternalRef ? internalRef : ref).current.children[0]
        .children[1].children[0];
      const rowHeight = row.clientHeight;

      // you need to let all the rows render but you dont have to let all of the columns
      // eslint-disable-next-line no-param-reassign
      // ref.current.height = `${rowHeight * dataLength}px`;
      // This counts down to 0 by the time it hits the bottom
      const scrollRemaining = scrollHeight - clientHeight - scrollTop;
      // How many elements can you actually see
      const visibleDataLength = Math.floor(clientHeight / rowHeight);
      // Elements you have already scrolled through
      const hiddenTopElements = Math.ceil(scrollTop / rowHeight);

      // How many elements should we have in total
      const totalElements = visibleDataLength * buffer;

      // What rows to render. Start index is always zero so the amount of data just grows
      const startIndex = 0;
      const endIndex = Math.min(
        Math.max(
          startIndex + hiddenTopElements + totalElements,
          arrIndices.end,
        ),
        dataLength,
      );

      if (startIndex !== arrIndices.start || endIndex !== arrIndices.end) {
        if (scrollRemaining > 1) {
          // MARK: Don't update unless too close to the edge of the buffer
          if (
            totalElements / 2 <
            hiddenTopElements
            // totalElements - hiddenTopElements <
            // (visibleDataLength * buffer) / 2
          ) {
            updateArrayIndices({ start: startIndex, end: endIndex });
          }
        }
      }
    };

    const divScrolled = (e: any) => {
      try {
        checkData(
          e.target.scrollHeight,
          e.target.clientHeight,
          e.target.scrollTop,
        );
      } catch {
        //
      }
    };

    useEffect(() => {
      if (dataLength) {
        try {
          const { scrollHeight, clientHeight, scrollTop } =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (useInternalRef ? internalRef : ref).current;
          checkData(scrollHeight, clientHeight, scrollTop);
        } catch {
          //
        }
      }
    }, [dataLength]);

    return (
      <ST.Wrapper
        ref={useInternalRef ? internalRef : ref}
        id={scrollID}
        onScroll={divScrolled}
        autoscroll={autoscroll}
      >
        {children(arrIndices.start, arrIndices.end)}
      </ST.Wrapper>
    );
  },
);

export default memo(LazyScrollableTable);
