import React, { memo, useState } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { COLORS, FONTS, FONT_SIZE, PADDING, ZINDEX } from '@constants/styles';
import { TIME_ICON } from '@constants/icons';
import { TextAlignTypes } from '@constants/commonTypes';
import { formatByType } from '@utils/formatting';

import InputWrapper from '../InputWrapper';

const ST = {
  Wrapper: styled.div`
    width: 100%;
  `,
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .MuiFormControl-root {
      width: 100%;
    }

    .MuiOutlinedInput-notchedOutline {
      padding-right: 0px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0px;
      outline: 0px;
    }

    .MuiOutlinedInput-notchedOutline:focus {
      border: 0px;
      outline: 0px;
    }

    .Mui-error .MuiOutlinedInput-notchedOutline {
      border: 0px;
      outline: 0px;
    }
  `,
  ClearButton: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: ${PADDING.SM};
    :hover {
      path {
        stroke-width: 5px;
        stroke: ${COLORS.MAIN};
        fill: ${COLORS.MAIN};
      }
    }
  `,
};

interface Props {
  value: string | null;
  onChange: (a: string | null) => void;
  minutesStep?: number;
  disableOpenPicker?: boolean;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
  hideIcon?: boolean;
  textAlign?: TextAlignTypes;
}

const CustomTimePicker: React.FC<Props> = ({
  value,
  onChange,
  minutesStep = 1,
  disableOpenPicker = false,
  color = COLORS.BLACK,
  fontSize = FONT_SIZE.PX16,
  fontFamily = FONTS.NORMAL,
  hideIcon = false,
  textAlign = 'right',
}) => {
  const [isOpen, updateIsOpen] = useState(false);
  const timeUpdated = (val: Date | null) => {
    if (val) {
      onChange(formatByType({ type: 'time', val }) as string);
    } else {
      onChange(null);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.MAIN,
      },
      secondary: {
        main: COLORS.BRIGHT_GREEN,
      },
    },
  });

  return (
    <InputWrapper leadingIcon={!hideIcon && TIME_ICON}>
      <ST.Container>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              open={isOpen}
              onOpen={() => updateIsOpen(true)}
              onClose={() => updateIsOpen(false)}
              value={value ? new Date(`06/01/2022T${value}`) : null}
              onChange={timeUpdated}
              renderInput={(params: any) => {
                const tempParams = params;
                if (!('inputProps' in tempParams)) {
                  tempParams.inputProps = { style: {} };
                }
                if (!('style' in tempParams.inputProps)) {
                  tempParams.inputProps.style = {};
                }
                tempParams.inputProps = {
                  ...tempParams.inputProps,
                  style: {
                    ...tempParams.style,
                    textAlign,
                    width: '100%',
                    fontFamily,
                    fontSize,
                    color,
                    cursor: 'pointer',
                    paddingTop: PADDING.MD,
                    paddingBottom: PADDING.MD,
                    paddingRight: PADDING.SM,
                    borderWidth: '0px',
                  },
                  readOnly: true,
                  placeholder: 'Choose Time',
                };
                return (
                  <TextField
                    {...tempParams}
                    onClick={() => {
                      updateIsOpen(true);
                    }}
                  />
                );
              }}
              PopperProps={{
                sx: { zIndex: ZINDEX.EXTRA_HIGH },
              }}
              minutesStep={minutesStep}
              disableOpenPicker={disableOpenPicker}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </ST.Container>
    </InputWrapper>
  );
};

export default memo(CustomTimePicker);
