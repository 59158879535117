import React, { memo } from 'react';
import styled from 'styled-components';
import { COLORS } from '@constants/styles';
import FormSpacer from '../FormSpacer';

const ST = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
};

interface Props {
  num?: number;
  colorNum?: number;
  backgroundColor?: string;
}

const SectionSpacer: React.FC<Props> = ({
  num = 2,
  colorNum = 0.25,
  backgroundColor = COLORS.TABLE_GRAY,
}) => (
  <ST.Wrapper>
    <FormSpacer num={num} />
    <FormSpacer num={colorNum} backgroundColor={backgroundColor} />
    <FormSpacer num={num} />
  </ST.Wrapper>
);

export default memo(SectionSpacer);
