import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';

import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';

const ST = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 5px;
    color: ${COLORS.BLACK};
    pointer: cursor;
    margin-bottom: ${PADDING.SM};
  `,
  Checkbox: styled.div`
    width: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  Label: styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    font-size: ${FONT_SIZE.PX16};
    font-family: ${FONTS.NORMAL};
    cursor: pointer;
    ${NO_SELECT}
  `,
  ControlWrapper: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  ControlButton: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${PADDING.XS};
    color: ${COLORS.WHITE};
    cursor: pointer;

    :hover {
      color: ${COLORS.ORANGE};
    }
  `,
};

interface Props {
  checkKey: string;
  updateCheck: (a: string) => void;
  checked: boolean;
  label?: string;
  controls?: Array<{
    key: string;
    icon: (a: string) => ReactNode;
    // eslint-disable-next-line @typescript-eslint/ban-types
    fcn: Function;
  }>;
}

const CheckboxRow: React.FC<Props> = ({
  checkKey,
  label,
  checked,
  updateCheck,
  controls = [],
}) => {
  const checkboxChecked = () => {
    updateCheck(checkKey);
  };

  return (
    <ST.Wrapper onClick={checkboxChecked}>
      <ST.Checkbox>
        {checked ? (
          <MdCheckBox size={20} color={COLORS.MAIN} />
        ) : (
          <MdCheckBoxOutlineBlank size={20} />
        )}
      </ST.Checkbox>
      <ST.Label>{label}</ST.Label>
      <ST.ControlWrapper>
        {controls.map(d => (
          <ST.ControlButton
            key={d.key}
            onClick={() => {
              d.fcn(checkKey)();
            }}
          >
            {d.icon(checkKey)}
          </ST.ControlButton>
        ))}
      </ST.ControlWrapper>
    </ST.Wrapper>
  );
};

export default memo(CheckboxRow);
