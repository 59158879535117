import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';
import FormSpacer from '@components/CustomForm/components/FormSpacer';
import { COLORS, FONTS, FONT_SIZE, PADDING } from '@constants/styles';

import { BUTTON_TYPE } from '@components/MultiButtonRow';
import InlineProperty from '../InlineProperty';
import StackedProperty from '../StackedProperty';

export type PropertyStackerType = 'STACKED' | 'INLINE';

const ST = {
  Wrapper: styled.div<{ hidePadding: boolean }>`
    width: 100%;
    padding-bottom: ${p => (p.hidePadding ? '0px' : PADDING.MD)};
    display: flex;
    flex-direction: column;
  `,
  ErrorMessage: styled.div`
    font-family: ${FONTS.NORMAL};
    font-size: ${FONT_SIZE.PX14};
    color: ${COLORS.DARK_RED};
    text-align: left;
  `,
};

interface Props {
  stackType: PropertyStackerType;
  title?: {
    label: string;
    size?: string;
    color?: string;
    font?: string;
    buttons?: Array<BUTTON_TYPE>;
    selectable?: boolean;
  };
  helpText?: { key: string; text: string };
  children?: ReactNode;
  description?: string;
  inputWidth?: number;
  hideBottomPadding?: boolean;
  error?: string;
}

const PropertyStacker: React.FC<Props> = ({
  stackType,
  title,
  helpText,
  children,
  description,
  inputWidth,
  hideBottomPadding = false,
  error,
}) => (
  <ST.Wrapper hidePadding={hideBottomPadding}>
    {stackType === 'STACKED' && (
      <StackedProperty
        title={title}
        helpText={helpText}
        description={description}
      >
        {children}
      </StackedProperty>
    )}
    {stackType === 'INLINE' && (
      <InlineProperty title={title} helpText={helpText} inputWidth={inputWidth}>
        {children}
      </InlineProperty>
    )}
    {error && (
      <>
        <FormSpacer num={1} />
        <ST.ErrorMessage> {error}</ST.ErrorMessage>
      </>
    )}
  </ST.Wrapper>
);

export default memo(PropertyStacker);
