import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks';
import { COLORS, PADDING, FONTS, FONT_SIZE } from '@constants/styles';
import { selectCollapse } from '@containers/App/state/selectors';
import { TextAlignTypes } from '@constants/commonTypes';

const ST = {
  Wrapper: styled.td<{
    fontSize: string;
    baseColor: string;
    fontFamily: string;
    colWidth?: number;
    fixed?: boolean;
    left?: number;
    rightBorder?: boolean;
    highlight?: boolean;
    highlightBkg: string;
    highlightColor: string;
    textAlign?: TextAlignTypes;
    horizontalPadding: string;
    verticalPadding: string;
    collapse: boolean;
  }>`
    ${p => (p.colWidth !== undefined ? `width: ${p.colWidth}px;` : '')}
    padding: ${p => p.verticalPadding} ${p => p.horizontalPadding};
    color: ${COLORS.BLACK};
    text-align: ${p => p.textAlign || 'right'};
    font-family: ${p => p.fontFamily};
    font-size: ${p => p.fontSize};
    overflow: hidden;

    ${p =>
      p.fixed &&
      !p.collapse &&
      `
        position: sticky;
        left: ${p.left}px;
        z-index: 2;
        ${p.rightBorder && 'border-right: 0.75px solid #d3d3d3;'}
        
    `};

    ${p =>
      p.fixed &&
      `
        text-align: left !important;
        color: ${COLORS.TEXT};
        background-color: ${COLORS.WHITE};
        font-family: ${FONTS.MEDIUM};
    `};

    ${p =>
      p.highlight
        ? `
            background-color: ${p.highlightBkg} !important;
            color: ${p.highlightColor};
          `
        : `
            color: ${p.baseColor};
      `}
  `,
};

interface Props {
  colNumber: number;
  numFixedColumns: number;
  children: ReactNode;
  fontSize?: string;
  baseColor?: string;
  fontFamily?: string;
  fixed?: boolean;
  textAlign?: TextAlignTypes;
  highlight?: boolean;
  highlightBkg?: string;
  highlightColor?: string;
  colWidth?: number;
  colSpan?: number;
  horizontalPadding?: string;
  verticalPadding?: string;
  helpAnchor?: string;
  helpText?: string;
}

const TableBodyCell: React.FC<Props> = ({
  colNumber,
  numFixedColumns,
  children,
  colWidth,
  fontSize = FONT_SIZE.PX16,
  baseColor = COLORS.BLACK,
  fontFamily = FONTS.NORMAL,
  fixed = false,
  textAlign = 'right',
  highlight = false,
  highlightBkg = COLORS.MAIN,
  highlightColor = COLORS.WHITE,
  colSpan = 1,
  horizontalPadding = PADDING.SM,
  verticalPadding = PADDING.SM,
  helpAnchor = '',
  helpText = '',
}) => {
  const collapse = useAppSelector(selectCollapse);

  return (
    <ST.Wrapper
      // id={helpAnchor}
      data-tooltip-id={helpAnchor}
      data-tooltip-content={helpText}
      fontSize={fontSize}
      baseColor={baseColor}
      fontFamily={fontFamily}
      fixed={fixed}
      left={fixed && colWidth !== undefined ? colWidth * colNumber : undefined}
      collapse={collapse}
      colWidth={colWidth}
      rightBorder={fixed && colNumber === numFixedColumns - 1}
      highlight={highlight}
      highlightBkg={highlightBkg}
      highlightColor={highlightColor}
      textAlign={textAlign}
      colSpan={colSpan}
      horizontalPadding={horizontalPadding}
      verticalPadding={verticalPadding}
    >
      {children}
    </ST.Wrapper>
  );
};

export default memo(TableBodyCell);
