import React, { memo, useState } from 'react';
import styled from 'styled-components';

const ST = {
  Image: styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  `,
};

interface Props {
  image: string;
  defaultImage?: string;
}

const RenderImage: React.FC<Props> = ({ image, defaultImage }) => {
  const [imgError, updateImgError] = useState(false);

  return imgError || image === undefined ? (
    <ST.Image
      src={defaultImage || `${process.env.PUBLIC_URL}/images/UNKNOWN_IMAGE.png`}
    />
  ) : (
    <ST.Image
      src={image}
      onError={e => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.target.onerror = null;
        updateImgError(true);
      }}
    />
  );
};

export default memo(RenderImage);
