import { Auth } from 'aws-amplify';
import axios from 'axios';
import { apiErrorProcessing } from '@constants/error_messages';
import { HTTP_API_URL } from '@constants/endpoints';

export interface getGlobalUsernameType {}

export interface getGlobalUsernameReturnType {
  firstname: string;
  lastname: string;
}
export default class GlobalAPIs {
  static async getTokenAPI() {
    return Auth.currentSession()
      .then(res => {
        const accessToken = res.getAccessToken();
        const jwt = accessToken.getJwtToken();

        return jwt;
      })
      .catch(() => null);
  }

  static getGlobalUsernameAPI(token: string, payload: getGlobalUsernameType) {
    return axios
      .post(`${HTTP_API_URL}/auth/getUsername`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getGlobalUsernameReturnType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
