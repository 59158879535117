import React, { memo, ReactNode, useState } from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import styled from 'styled-components';
import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';
import MultiButtonRow, { BUTTON_TYPE } from '@components/MultiButtonRow';

import InputWrapper from '../InputWrapper';

const ST = {
  Input: styled.input<{
    backgroundColor: string;
    color: string;
    fontFamily: string;
    textAlign: string;
    fontSize: string;
  }>`
    width: 100%;
    background-color: transparent;
    border: 0px;

    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
    text-align: ${p => p.textAlign};
    font-family: ${p => p.fontFamily};
    font-size: ${p => p.fontSize};
    padding: ${PADDING.MD} ${PADDING.SM};

    :focus {
      outline: none;
      border: none;
    }

    :disabled {
      border: 0px;
      ${NO_SELECT}
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
  `,
  Icon: styled.div`
    width: 50px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${COLORS.GRAY};

    :hover {
      color: ${COLORS.MAIN};
    }
  `,
};

interface Props {
  type: 'text' | 'password';
  value: string;
  icon?: ReactNode;
  onChange?: (a: string) => void;
  textAlign?: string;
  fontSize?: string;
  fontFamily?: string;
  backgroundColor?: string;
  color?: string;
  disabled?: boolean;
  placeholder?: string;
  enterListener?: () => void;
  hidePasswordToggle?: boolean;
  iconButtons?: Array<BUTTON_TYPE>;
}

const CustomTextInput: React.FC<Props> = ({
  type,
  value,
  icon,
  onChange = () => {},
  textAlign = 'left',
  fontSize = FONT_SIZE.PX16,
  fontFamily = FONTS.NORMAL,
  backgroundColor = COLORS.TRANSPARENT,
  color = COLORS.BLACK,
  disabled = false,
  placeholder,
  enterListener = () => {},
  hidePasswordToggle = false,
  iconButtons = [],
}) => {
  const [passToggle, updatePassToggle] = useState(false);
  const updateInput = (ev: any) => {
    onChange(ev.target.value);
  };

  const toggleViewPassword = () => {
    updatePassToggle(!passToggle);
  };

  const enterFcn = (e: { key: string }) => {
    if (e.key === 'Enter') {
      enterListener();
    }
  };

  return (
    <InputWrapper leadingIcon={icon} hideBorder={disabled}>
      <ST.Input
        type={
          !hidePasswordToggle && type === 'password' && passToggle
            ? 'text'
            : type
        }
        value={`${value}`}
        onChange={updateInput}
        textAlign={textAlign}
        onWheel={e => (e.target as HTMLElement).blur()}
        backgroundColor={backgroundColor}
        color={color}
        fontSize={fontSize}
        fontFamily={fontFamily}
        disabled={disabled}
        placeholder={placeholder}
        onKeyUp={enterFcn}
      />
      {iconButtons.map(d => (
        <ST.Icon key={d.key}>
          <MultiButtonRow buttons={[d]} />
        </ST.Icon>
      ))}
      {!hidePasswordToggle && type === 'password' && (
        <ST.Icon onClick={toggleViewPassword}>
          {passToggle ? <BsEyeFill /> : <BsEyeSlashFill />}
        </ST.Icon>
      )}
    </InputWrapper>
  );
};

export default memo(CustomTextInput);
