import axios from 'axios';
import { HTTP_API_URL } from '@constants/endpoints';
import { apiErrorProcessing } from '@constants/error_messages';
import { ReportTypes } from '@containers/SurveyPage/state/apis';

export interface getNewProfileIDType {
  reportType: ReportTypes;
  profileID: string;
}
export interface getNewProfileIDResponseType {
  profileID: string;
}

export default class ReportRedirectPageAPIs {
  static getNewProfileIDAPI(token: string, payload: getNewProfileIDType) {
    return axios
      .post(`${HTTP_API_URL}/survey/getNewProfileID`, payload, {})
      .then((result: { data: getNewProfileIDResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
