import React, { memo, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { NO_SELECT, OVERFLOW_STYLE, PADDING } from '@constants/styles';
import LineGraph from '@components/Graphs/LineGraph';
import BarGraph from '@components/Graphs/BarGraph';
import ImageTextRow from '@components/PageBuilder/components/ImageTextRow';

import CustomTextInput from './components/CustomTextInput';
import MultiCheckboxGroup from './components/MultiCheckboxGroup';
import CustomNumArrows from './components/CustomNumArrows';
import CustomSwitch from './components/CustomSwitch';
import RadioGroup from './components/RadioGroup';
import CustomTimePicker from './components/CustomTimePicker';
import CustomDatePicker from './components/CustomDatePicker';
import CustomNumberInput from './components/CustomNumberInput';
import CustomRangeSlider from './components/CustomRangeSlider';
import RawText from './components/RawText';
import CustomPhoneInput from './components/CustomPhoneInput';
import CustomTextArea from './components/CustomTextArea';
import SectionSpacer from './components/SectionSpacer';
import ListText from './components/ListText';
import CustomFileInput from './components/CustomFileInput';
import CustomImage from './components/CustomImage';
import FormSpacer from './components/FormSpacer';
import PendingSection from './components/PendingSection';

import CustomTable from '../CustomTable';
import LazyScrollableTable from '../LazyScrollableTable';
import MultiButtonRow from '../MultiButtonRow';
import SingleSelectDropdown from '../Dropdowns/SingleSelectDropdown';
import MultiSelectDropdown from '../Dropdowns/MultiSelectDropdown';

import PropertyStacker from './stackers/PropertyStacker';

import { FormOptionType } from './types';
import SingleCheckbox from './components/SingleCheckbox';
import CustomSlider from './components/CustomSlider';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    ${OVERFLOW_STYLE(false, true, true)}
  `,
  Row: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  `,
  FullProperty: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,

  PaddedProperty: styled.div<{
    minWidth: string;
    horizontalPadding: string;
    width?: string;
  }>`
    display: flex;
    flex-direction: row;
    padding: 0px ${p => p.horizontalPadding};
    align-items: center;
    ${p =>
      p.width
        ? `width: ${p.width};`
        : `min-width: ${p.minWidth}; width: 100%; flex: 1;`}

    overflow: hidden;
  `,
  TableWrapper: styled.div<{ maxHeight: string; overrideOverflow: boolean }>`
    width: 100%;
    max-height: ${p => p.maxHeight};
    ${p => (p.overrideOverflow ? '' : OVERFLOW_STYLE(true, true, true))}

    ${NO_SELECT};
  `,
  LazyTableWrapper: styled.div<{ maxHeight: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: ${p => p.maxHeight};
    overflow: hidden;

    ${NO_SELECT};
  `,
  TableControlButtonWrapper: styled.div`
    height: 30px;
    width: 100%;
  `,
  SliderWrapper: styled.div<{ height?: number }>`
    width: 100%;
    height: ${p => p.height}px;
  `,
  PendingWrapper: styled.div<{ width: string; height: string }>`
    width: ${p => p.width};
    height: ${p => p.height};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

export type ValidationFcnType = () => { [key: string]: string };

interface Props {
  options: Array<FormOptionType>;
  validationFcn?: ValidationFcnType;
  hideSave?: boolean;
  saveText?: string;
  saveFcn?: () => void;
  inTable?: boolean;
  externalErrors?: { [key: string]: string };
  horizontalPadding?: string;
}

const CustomForm: React.FC<Props> = ({
  options,
  validationFcn = () => ({}),
  hideSave = false,
  saveText = 'Save',
  saveFcn = () => {},
  inTable = false,
  externalErrors = {},
  horizontalPadding = PADDING.XL,
}) => {
  const [errors, updateErrors] = useState<{ [key: string]: string }>({});
  const attemptSave = () => {
    const err = validationFcn();
    if (!isEmpty(err)) {
      updateErrors(err);
    } else {
      saveFcn();
    }
  };

  const allErrors = { ...errors, ...externalErrors };

  const chooseInput = (val: FormOptionType) => {
    const stackerProps = {
      key: val.key,
      title: val.title,
      hideBottomPadding: val.hideBottomPadding,
      error: val.error,
      stackType: val.stackType || 'STACKED',
      ...(val.stackType === 'STACKED' || val.stackType === undefined
        ? {
            rowStack: val.rowStack,
            minRowWidth: val.minRowWidth,
            fullWidth: val.fullWidth,
            description: val.description,
          }
        : {}),
    } as const;

    switch (val.type) {
      case 'title':
        return <PropertyStacker {...stackerProps} />;
      case 'rawText':
        return (
          <PropertyStacker {...stackerProps}>
            <RawText
              text={val.value}
              textAlign={val.textAlign}
              selectable={val.selectable}
            />
          </PropertyStacker>
        );
      case 'password':
      case 'text':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomTextInput
              type={val.type}
              value={val.value}
              icon={val.icon}
              onChange={val.updateValue}
              color={val.color}
              textAlign={val.textAlign}
              fontSize={val.fontSize}
              fontFamily={val.fontFamily}
              placeholder={val.placeholder}
              enterListener={val.enterListener}
              hidePasswordToggle={val.hidePasswordToggle}
              disabled={val.disabled}
              iconButtons={val.iconButtons}
            />
          </PropertyStacker>
        );
      case 'textArea':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomTextArea
              value={val.value}
              icon={val.icon}
              onChange={val.updateValue}
              color={val.color}
              textAlign={val.textAlign}
              fontSize={val.fontSize}
              fontFamily={val.fontFamily}
              disabled={val.disabled}
              placeholder={val.placeholder}
              enterListener={val.enterListener}
              rows={val.props?.rows}
              maxLength={val.props?.maxLength}
              enableResize={val.props?.enableResize}
            />
          </PropertyStacker>
        );
      case 'phone':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomPhoneInput
              value={val.value}
              icon={val.icon}
              onChange={val.updateValue}
              color={val.color}
              textAlign={val.textAlign}
              fontSize={val.fontSize}
              fontFamily={val.fontFamily}
              disabled={val.disabled}
              placeholder={val.placeholder}
              defaultCountry={val.defaultCountry}
            />
          </PropertyStacker>
        );
      case 'number':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomNumberInput
              value={val.value}
              onChange={val.updateValue}
              prefix={val.props?.prefix}
              suffix={val.props?.suffix}
              textAlign={val.props?.textAlign}
              disabled={val.props?.disabled}
              decimalScale={val.props?.decimalScale}
              min={val.props?.min}
              icon={val.icon}
            />
          </PropertyStacker>
        );
      case 'number_arrows':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomNumArrows
              value={val.value}
              updateValue={val.updateValue}
              prefix={val.props?.prefix}
              suffix={val.props?.suffix}
              disabled={val.props?.disabled}
              min={val.props?.min}
              max={val.props?.max}
              disableInput={val.props?.disableInput}
            />
          </PropertyStacker>
        );
      case 'toggle':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomSwitch
              val={val.value}
              updateVal={val.updateValue}
              fontSize={val.fontSize}
              emptyColor={val.emptyColor}
              selectedColor={val.selectedColor}
              highlightColor={val.highlightColor}
            />
          </PropertyStacker>
        );
      case 'radio':
        return (
          <PropertyStacker {...stackerProps}>
            <RadioGroup
              data={val.props?.options}
              selected={val.value}
              optionChanged={val.updateValue}
              direction={val.props?.direction}
              color={val.props?.color}
              fontSize={val.props?.fontSize}
              disabled={val.props?.disabled}
            />
          </PropertyStacker>
        );
      case 'checkbox':
        return (
          <PropertyStacker {...stackerProps}>
            <SingleCheckbox
              data={val.options}
              selected={val.value}
              optionChanged={val.updateValue}
            />
          </PropertyStacker>
        );
      case 'checkboxGroup':
        return (
          <PropertyStacker {...stackerProps}>
            <MultiCheckboxGroup
              data={val.props.options}
              selected={val.value}
              optionChanged={val.updateValue}
            />
          </PropertyStacker>
        );
      case 'dropdown':
        return (
          <PropertyStacker {...stackerProps}>
            <SingleSelectDropdown
              options={val.props.options}
              selected={val.value}
              updateSelected={val.updateValue}
              isSearchable={val.props?.isSearchable}
              textAlign={val.props?.textAlign}
            />
          </PropertyStacker>
        );
      case 'multi-dropdown':
        return (
          <PropertyStacker {...stackerProps}>
            <MultiSelectDropdown
              options={val.props.options}
              selected={val.value}
              updateSelected={val.updateValue}
              forceAllDefault={val.props.forceAllDefault}
            />
          </PropertyStacker>
        );

      case 'button':
        return (
          <PropertyStacker {...stackerProps}>
            <MultiButtonRow buttons={val.value || []} />
          </PropertyStacker>
        );
      case 'datePicker':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomDatePicker
              value={val.value}
              onChange={val.updateValue}
              disableOpenPicker={!val.props?.enableOpenPicker}
              hideIcon={val.props?.hideIcon}
              minDate={val.props?.minDate}
              maxDate={val.props?.maxDate}
              disabled={val.props?.disabled}
            />
          </PropertyStacker>
        );
      case 'timePicker':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomTimePicker
              value={val.value}
              onChange={val.updateValue}
              disableOpenPicker={!val.props?.enableOpenPicker}
              fontSize={val.props?.fontSize}
              hideIcon={val.props?.hideIcon}
              textAlign={val.props?.textAlign}
            />
          </PropertyStacker>
        );
      case 'table':
        return (
          <PropertyStacker {...stackerProps}>
            <ST.TableWrapper
              maxHeight={val.props?.maxHeight || '400px'}
              overrideOverflow={val.props?.maxHeight === '-1'}
            >
              {val.props?.buttons && (
                <ST.TableControlButtonWrapper>
                  <MultiButtonRow buttons={val.props?.buttons}></MultiButtonRow>
                </ST.TableControlButtonWrapper>
              )}

              <CustomTable
                data={val.value}
                cols={val.props.cols}
                sortKey={val.props?.tableSort}
                updateSortKey={val.props?.updateTableSort}
                showRowHover={val.props?.showTableHover}
                additionalHeader={val.props?.additionalHeader}
                headerFontSize={val.props?.headerFontSize}
                hideHeader={val.props?.hideHeader}
                borderColor={val.props?.borderColor}
                showHeaderBorder={val.props?.showHeaderBorder}
                hideRowBorder={val.props?.hideRowBorder}
              />
            </ST.TableWrapper>
          </PropertyStacker>
        );
      case 'lazy_table':
        return (
          <PropertyStacker {...stackerProps}>
            <ST.LazyTableWrapper maxHeight={val.props?.maxHeight || '400px'}>
              {val.props?.buttons && (
                <ST.TableControlButtonWrapper>
                  <MultiButtonRow buttons={val.props?.buttons}></MultiButtonRow>
                </ST.TableControlButtonWrapper>
              )}
              <LazyScrollableTable useInternalRef dataLength={val.value.length}>
                {(startIndex, endIndex) => (
                  <CustomTable
                    data={val.value.slice(startIndex, endIndex)}
                    cols={val.props.cols}
                    sortKey={val.props?.tableSort}
                    updateSortKey={val.props?.updateTableSort}
                    showRowHover={val.props?.showTableHover}
                    additionalHeader={val.props?.additionalHeader}
                    headerFontSize={val.props?.headerFontSize}
                  />
                )}
              </LazyScrollableTable>
            </ST.LazyTableWrapper>
          </PropertyStacker>
        );
      case 'slider':
        return (
          <PropertyStacker {...stackerProps}>
            <ST.SliderWrapper height={val.props?.height}>
              <CustomSlider
                value={val.value}
                updateValue={val.updateValue}
                min={val.props?.min}
                max={val.props?.max}
                step={val.props?.step}
                trackColor={val.props?.trackColor}
                railColor={val.props?.railColor}
                disabled={val.props?.disabled}
              />
            </ST.SliderWrapper>
          </PropertyStacker>
        );
      case 'range_slider':
        return (
          <PropertyStacker {...stackerProps}>
            <ST.SliderWrapper height={val.props?.height}>
              <CustomRangeSlider
                value={val.value}
                updateValue={val.updateValue}
                min={val.props?.min}
                max={val.props?.max}
                step={val.props?.step}
                railColor={val.props?.railColor}
                fixToEnds={val.props?.fixToEnds}
              />
            </ST.SliderWrapper>
          </PropertyStacker>
        );
      case 'plain_list':
      case 'numbered_list':
      case 'lowercase_letter_list':
        return (
          <PropertyStacker {...stackerProps}>
            <ListText
              key={val.key}
              items={val.value}
              textAlign={val.textAlign}
              selectable={val.selectable}
              bulletType={val.type}
            />
          </PropertyStacker>
        );
      case 'file':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomFileInput
              keyID={val.key}
              updateValue={val.updateValue}
              buttonText={val.buttonText}
              btnStyle={val.btnStyle}
              customStyles={val.customStyles}
            />
          </PropertyStacker>
        );
      case 'image':
        return (
          <PropertyStacker {...stackerProps}>
            <CustomImage
              image={val.image}
              width={val.width}
              height={val.height}
              fill={val.fill}
              position={val.position}
              alignment={val.alignment}
            />
          </PropertyStacker>
        );
      case 'image_text_row':
        return (
          <ImageTextRow
            key={val.key}
            image={'images/book_image.jpg'}
            height={300}
            textSection={val.text}
            tileColor={val.props?.textBackgroundColor}
            leftText={val.props?.leftText || false}
          />
        );
      case 'bar_graph':
        return (
          <BarGraph
            key={val.key}
            xLabels={val.xLabels}
            data={val.data}
            stacked={val.stacked}
            title={val?.chartTitle}
            subtitle={val?.subtitle}
            height={val.height}
            xAxisTitle={val.xAxisTitle}
            yAxisTitle={val.yAxisTitle}
            hideLegend={val.hideLegend}
            tooltipTitleCallback={val.tooltipTitleCallback}
            tooltipLabelCallback={val.tooltipLabelCallback}
            loadImage={val.loadImage}
          />
        );
      case 'line_graph':
        return (
          <LineGraph
            key={val.key}
            xLabels={val.xLabels}
            data={val.data}
            title={val?.chartTitle}
            subtitle={val?.subtitle}
            height={val.height}
            lineTension={val.lineTension}
            xAxisTitle={val.xAxisTitle}
            yAxisTitle={val.yAxisTitle}
            hideLegend={val.hideLegend}
            tooltipTitleCallback={val.tooltipTitleCallback}
            tooltipLabelCallback={val.tooltipLabelCallback}
            loadImage={val.loadImage}
          />
        );

      case 'spacer':
        return <FormSpacer key={val.key} num={val.num} />;
      case 'section_spacer':
        return (
          <SectionSpacer
            key={val.key}
            num={val.num}
            colorNum={val.colorNum}
            backgroundColor={val.backgroundColor}
          />
        );
      case 'pending':
        return (
          <ST.PendingWrapper height={val.props.height} width={val.props.width}>
            <PendingSection />
          </ST.PendingWrapper>
        );
      case 'page_break':
        return null;
      default:
        return null;
    }
  };

  const rows: Array<Array<ReactNode>> = [];
  options.forEach(d => {
    const newValue = {
      ...d,
      error: allErrors[d.key],
      ...((d.type === 'text' ||
        d.type === 'password' ||
        d.type === 'textArea') &&
      d.enableEnterSave
        ? { enterListener: attemptSave }
        : {}),
    };
    const renderComp =
      d.fullWidth || inTable ? (
        chooseInput({
          ...newValue,
          hideBottomPadding: d.hideBottomPadding || inTable,
        })
      ) : (
        <ST.PaddedProperty
          key={d.key}
          width={d.widthOverride}
          minWidth={d.minRowWidth ? `${d.minRowWidth}px` : '100%'}
          horizontalPadding={horizontalPadding}
        >
          {chooseInput(newValue)}
        </ST.PaddedProperty>
      );

    if (d.rowStack) {
      rows[rows.length - 1].push(renderComp);
    } else {
      rows.push([renderComp]);
    }
  });

  return (
    <ST.Wrapper>
      {rows.map((d, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ST.Row key={i}>{d}</ST.Row>
      ))}
      {!hideSave && (
        <>
          <FormSpacer num={6} />
          <ST.PaddedProperty
            minWidth={'100%'}
            horizontalPadding={horizontalPadding}
          >
            {chooseInput({
              key: 'confirm',
              type: 'button',
              value: [
                {
                  key: 'confirm',
                  text: saveText,
                  fcn: attemptSave,
                  style: 'MAIN',
                },
              ],
              hideBottomPadding: true,
            })}
          </ST.PaddedProperty>
          <FormSpacer num={6} />
        </>
      )}
    </ST.Wrapper>
  );
};

export default memo(CustomForm);
