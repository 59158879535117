import axios from 'axios';
import { HTTP_API_URL } from '@constants/endpoints';
import { apiErrorProcessing } from '@constants/error_messages';
import { UserPageState } from './reducer';

export interface GetUserPageType {}
export interface GetUserPageResponseType {
  user: UserPageState['userDetails'];
  subscription: UserPageState['subscriptionDetails'];
  profiles: UserPageState['profiles'];
}
export interface UpdateUserDetailsType {
  firstName: string;
  lastName: string;
}

export interface UpdateProfileNumType {
  profileNum: string;
}

export default class UserPageAPIs {
  static getUserPageAPI(token: string, payload: GetUserPageType) {
    return axios
      .post(`${HTTP_API_URL}/users/getUserPage`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: GetUserPageResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateUserDetailsAPI(token: string, payload: UpdateUserDetailsType) {
    return axios
      .post(`${HTTP_API_URL}/users/updateUser`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static updateProfileNumAPI(token: string, payload: UpdateProfileNumType) {
    return axios
      .post(`${HTTP_API_URL}/survey/addProfileByNum`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }
}
