import React, { ReactNode } from 'react';
import { FaMoneyBillAlt, FaUsers } from 'react-icons/fa';

import {
  HiClock,
  HiHashtag,
  HiLockClosed,
  HiMail,
  HiUser,
} from 'react-icons/hi';
import { RiCalendarEventFill } from 'react-icons/ri';
// import { BsBuildingFillAdd } from 'react-icons/bs';
import { GiBrain } from 'react-icons/gi';
import { IoIosAddCircle } from 'react-icons/io';
import { FiTrash2 } from 'react-icons/fi';

export const EMAIL_ICON: ReactNode = <HiMail size={20} />;
export const USER_ICON: ReactNode = <HiUser size={20} />;
export const TEAM_ICON: ReactNode = <FaUsers size={20} />;
export const PASSWORD_ICON: ReactNode = <HiLockClosed size={20} />;
export const CODE_ICON: ReactNode = <HiHashtag size={20} />;
export const CALENDAR_ICON: ReactNode = <RiCalendarEventFill size={20} />;
export const TIME_ICON: ReactNode = <HiClock size={20} />;

// export const MONEY_ICON = <HiCash size={20} />;
export const MONEY_ICON: ReactNode = <FaMoneyBillAlt size={20} />;
// export const BUILDING_ICON: ReactNode = <BsBuildingFillAdd size={20} />;
export const BRAIN_ICON: ReactNode = <GiBrain size={20} />;
export const CIRCLE_ADD: ReactNode = <IoIosAddCircle size={20} />;

export const TRASH_ICON = <FiTrash2 size={20} />;
