import React, { memo } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { COLORS, PADDING, ZINDEX } from '@constants/styles';

const ST = {
  Wrapper: styled.div`
    cursor: help;
    margin-left: ${PADDING.XS};
    color: ${COLORS.MAIN};

    .custom-tooltip {
      text-align: center !important;
      max-width: 200px;
      z-index: ${ZINDEX.HIGH};
      opacity: 1;
    }

    svg {
      transform: translate(0, 4px);
    }
  `,
};

interface Props {
  tooltipKey: string;
}

const TableTooltip: React.FC<Props> = ({ tooltipKey }) => (
  <ST.Wrapper>
    <Tooltip id={tooltipKey} className={'custom-tooltip'} place={'top'} />
  </ST.Wrapper>
);

export default memo(TableTooltip);
