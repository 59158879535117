import MultiButtonRow, { BUTTON_TYPE } from '@components/MultiButtonRow';
import {
  COLORS,
  FONTS,
  FONT_SIZE,
  PADDING,
  SHADOW_GENERATOR,
  ZINDEX,
} from '@constants/styles';
import React, { ReactNode, memo } from 'react';
import styled from 'styled-components';

const ST = {
  Wrapper: styled.div<{ insideContainer: boolean }>`
    position: ${p => (p.insideContainer ? 'absolute' : 'fixed')};
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: ${ZINDEX.EXTRA_HIGH};
  `,
  Shadow: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: black;
    opacity: 0.3;
  `,
  Container: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  ContentWrapper: styled.div<{
    backgroundColor: string;
    maxWidth: number;
  }>`
    background-color: ${p => p.backgroundColor};
    border-radius: 8px;
    border: 1px solid ${COLORS.REAL_BLACK};
    max-height: 90%;
    width: 90%;
    max-width: ${p => p.maxWidth}px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: ${PADDING.XL};

    ${SHADOW_GENERATOR(2, 2, 2)}
  `,
  Content: styled.div`
    display: flex;
  `,
  Icon: styled.div<{ color?: string }>`
    color: ${p => p.color};
    margin-right: ${PADDING.LG};
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  TextWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled.div<{ color: string }>`
    font-size: ${FONT_SIZE.PX16};
    font-family: ${FONTS.SEMI_BOLD};
    color: ${p => p.color};
    text-align: left;
    margin-top: ${PADDING.SM};
  `,
  Message: styled.div<{ color: string }>`
    font-size: ${FONT_SIZE.PX14};
    font-family: ${FONTS.NORMAL};
    color: ${p => p.color};
    text-align: left;
    margin-top: ${PADDING.SM};
  `,
  ButtonWrapper: styled.div`
    margin-top: ${PADDING.XL};
  `,
};

interface Props {
  icon?: ReactNode;
  iconColor?: string;
  title?: string;
  message?: string;
  primaryFcn: () => void;
  primaryText: string;
  primaryStyle?: BUTTON_TYPE['style'];
  secondaryFcn?: () => void;
  secondaryText?: string;
  secondaryStyle?: BUTTON_TYPE['style'];
  titleColor?: string;
  color?: string;
  backgroundColor?: string;
  maxWidth?: number;
  insideContainer?: boolean;
}

const DialogModal: React.FC<Props> = ({
  icon = undefined,
  iconColor = undefined,
  title = undefined,
  message = undefined,
  primaryFcn = undefined,
  primaryText = undefined,
  primaryStyle = 'MAIN',
  secondaryFcn = undefined,
  secondaryText = undefined,
  secondaryStyle = 'SKELETON',
  titleColor = undefined,
  color = COLORS.BLACK,
  backgroundColor = COLORS.WHITE,
  maxWidth = 500,
  insideContainer = false,
}) => (
  <ST.Wrapper insideContainer={insideContainer}>
    <ST.Shadow />
    <ST.Container>
      <ST.ContentWrapper backgroundColor={backgroundColor} maxWidth={maxWidth}>
        <ST.Content>
          {icon !== undefined && <ST.Icon color={iconColor}>{icon}</ST.Icon>}
          <ST.TextWrapper>
            {title !== undefined && (
              <ST.Title color={titleColor || color}>{title}</ST.Title>
            )}
            {message !== undefined && (
              <ST.Message color={color}>{message}</ST.Message>
            )}
          </ST.TextWrapper>
        </ST.Content>
        <ST.ButtonWrapper>
          <MultiButtonRow
            buttons={[
              ...((secondaryFcn
                ? [
                    {
                      key: 'secondary',
                      text: secondaryText,
                      style: secondaryStyle,
                      fcn: secondaryFcn || (() => {}),
                      disabled: secondaryFcn === undefined,
                    },
                  ]
                : []) as Array<BUTTON_TYPE>),
              {
                key: 'primary',
                text: primaryText,
                style: primaryStyle,
                fcn: primaryFcn || (() => {}),
                disabled: primaryFcn === undefined,
                custom: {
                  border: 1,
                },
              },
            ]}
          />
        </ST.ButtonWrapper>
      </ST.ContentWrapper>
    </ST.Container>
  </ST.Wrapper>
);

export default memo(DialogModal);
