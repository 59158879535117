import React, { memo } from 'react';
import styled from 'styled-components';
import HashLoader from 'react-spinners/HashLoader';
import { COLORS } from '@constants/styles';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
};

interface Props {
  color?: string;
  size?: number;
  speedMultiplier?: number;
}

const PendingSection: React.FC<Props> = ({
  color = COLORS.MAIN,
  size = 50,
  speedMultiplier = 1,
}) => (
  <ST.Wrapper>
    <HashLoader color={color} size={size} speedMultiplier={speedMultiplier} />
  </ST.Wrapper>
);

export default memo(PendingSection);
