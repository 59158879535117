import React, { memo } from 'react';
import styled from 'styled-components';

const ST = {
  Wrapper: styled.div<{ alignment: 'flex-start' | 'center' | 'flex-end' }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
  Image: styled.div<{
    image: string;
    width: string;
    height: string;
    fill: 'cover' | 'contain';
    position: 'left' | 'right' | 'center';
  }>`
    width: ${p => p.width};
    height: ${p => p.height};
    background-image: ${p => `url(${p.image})`};
    background-position: ${p => p.position};
    background-size: ${p => p.fill};
    background-repeat: no-repeat;
  `,
};

interface Props {
  image: string;
  width?: string;
  height?: string;
  fill?: 'cover' | 'contain';
  position?: 'left' | 'right' | 'center';
  alignment?: 'flex-start' | 'center' | 'flex-end';
}

const CustomImage: React.FC<Props> = ({
  image,
  width = '100px',
  height = '100px',
  fill = 'contain',
  position = 'center',
  alignment = 'flex-start',
}) => (
  <ST.Wrapper alignment={alignment}>
    <ST.Image
      image={image}
      width={width}
      height={height}
      fill={fill}
      position={position}
    />
  </ST.Wrapper>
);

export default memo(CustomImage);
