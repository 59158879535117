/* eslint-disable indent */
import React, { memo, ReactNode, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import useSize from '@react-hook/size';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { COLORS, ZINDEX } from '@constants/styles';
import { useAppSelector } from '@app/hooks';
import { selectCollapse } from '@containers/App/state/selectors';

const ST = {
  Wrapper: styled.div<{ height: number }>`
    width: 100%;
    min-height: ${p => p.height}px;

    display: flex;
    flex-direction: row;
  `,
  Section: styled.div<{
    tileColor: string;
    paddingTop: string;
    paddingRight: string;
    paddingBottom: string;
    paddingLeft: string;
  }>`
    background-color: ${p => p.tileColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${p =>
      `${p.paddingTop} ${p.paddingRight} ${p.paddingBottom} ${p.paddingLeft}`};
    height: 100%;
  `,
  LeftSection: styled.div<{ flex: number; flushLeft: boolean }>`
    display: flex;
    flex: ${p => p.flex};
    overflow: hidden;
    justify-content: center;
    align-items: center;
    // padding: 20px;
    position: relative;
    ${p => (p.flushLeft ? 'padding-left: 0px;' : '')}
  `,
  RightSection: styled.div<{ flex: number; flushRight: boolean }>`
    display: flex;
    flex: ${p => p.flex};
    overflow: hidden;
    justify-content: center;
    align-items: center;
    // padding: 20px;
    position: relative;
    ${p => (p.flushRight ? 'padding-right: 0px;' : '')}
  `,
  ImageWrapper: styled.div`
    height: 100%;
    width: 100%;
    position: relative;
  `,
  ImageForeground: styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    // justify-content: center;
    align-items: center;
  `,
  TimerForeground: styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  ImageContainer: styled.div<{
    height: number;
    width: number;
    backgroundColor: string;
  }>`
    height: ${p => (p.height ? `${p.height}px` : '100%')};
    width: ${p => (p.width ? `${p.width}px` : '100%')};
    background-color: ${p => p.backgroundColor};
    position: relative;

    .slick-slider,
    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-track {
      display: flex;
    }
    .slick-track .slick-slide {
      display: flex;
      height: auto;
    }

    .slick-slide div {
      height: 100%;
      width: 100%;
    }
  `,
  Image: styled.div<{ image: string; position: string }>`
    height: 100%;
    width: 100%;
    background-image: ${p => `url("${process.env.PUBLIC_URL}/${p.image}")`};
    background-position: ${p => p.position};
    background-size: contain;
    background-repeat: no-repeat;
  `,
  Fullsize: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  `,
  CollapsedImageWrapper: styled.div<{ height: number }>`
    position: relative;
    height: ${p => p.height}px;
    margin-bottom: 10px;
  `,
  CollapsedTextWrapper: styled.div<{ backgroundColor: string }>`
    position: relative;
    background-color: ${p => p.backgroundColor};
  `,
  CollapsedHolder: styled.div`
    width: 100%;
    padding: 20px;
  `,
  SlideImageBackground: styled.div<{ bkg: string }>`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${p => p.bkg};
  `,
  SlideImage: styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    z-index: ${ZINDEX.MED};
  `,
};

interface Props {
  textSection: ReactNode;
  image?: string;
  slides?: Array<string>;
  leftText?: boolean;
  tileColor?: string;
  flushLeft?: boolean;
  flushRight?: boolean;
  slideScale?: number;
  slideBackgroundColor?: string;
  height?: number;
  overwriteSection?: ReactNode;
  speed?: number;
  autoplaySpeed?: number;
  overwriteTextPadding?: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
  forceCollapse?: boolean;
}

const ImageTextRow: React.FC<Props> = ({
  textSection,
  image = null,
  slides = [],
  leftText = false,
  tileColor = COLORS.MAIN,
  flushLeft = false,
  flushRight = false,
  slideScale = 1,
  slideBackgroundColor = COLORS.TRANSPARENT,
  height = 350,
  overwriteSection = null,
  speed = 500,
  autoplaySpeed = 3000,
  overwriteTextPadding = {},
  forceCollapse = false,
}) => {
  const collapse = useAppSelector(selectCollapse) || forceCollapse;
  const fullSizeRef = useRef(null);
  const [imageWidth, imageHeight] = useSize(fullSizeRef);

  const newWidth = imageWidth * slideScale;
  const newHeight = imageHeight * slideScale;
  const originalHeight = 295;
  const originalWidth = 515;

  let scaledWidth = newWidth;
  let scaleRatio = newWidth / originalWidth;
  let scaledHeight = originalHeight * scaleRatio;

  if (scaledHeight > newHeight) {
    scaledHeight = newHeight;
    scaleRatio = newHeight / originalHeight;
    scaledWidth = originalWidth * scaleRatio;
  }

  if (slideScale === 1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scaledHeight = null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scaledWidth = null;
  }

  const leftFlex = leftText ? 3 : 2;
  const rightFlex = leftText ? 2 : 3;

  const imagePosition =
    // eslint-disable-next-line no-nested-ternary
    flushLeft || flushRight ? (flushLeft ? 'left' : 'right') : 'center';

  const sliderImage = (
    <ST.ImageWrapper>
      {slides.length > 0 && (
        <ST.ImageForeground>
          <ST.Fullsize ref={fullSizeRef}>
            <ST.ImageContainer
              backgroundColor={slideBackgroundColor}
              height={scaledHeight}
              width={scaledWidth}
            >
              <Slider
                arrows={false}
                infinite
                dots={false}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay={slides.length > 1}
                autoplaySpeed={autoplaySpeed}
                speed={speed}
              >
                {slides.map((d, i) => (
                  <React.Fragment key={d}>
                    <ST.SlideImageBackground
                      bkg={
                        Array.isArray(slideBackgroundColor)
                          ? slideBackgroundColor[i]
                          : slideBackgroundColor
                      }
                    />
                    <ST.SlideImage>
                      <ST.Image image={d} position={'center'} />
                    </ST.SlideImage>
                  </React.Fragment>
                ))}
              </Slider>
            </ST.ImageContainer>
          </ST.Fullsize>
        </ST.ImageForeground>
      )}
      {image && (
        <ST.ImageForeground>
          <ST.Image image={image} position={imagePosition} />
        </ST.ImageForeground>
      )}
      {overwriteSection}
    </ST.ImageWrapper>
  );
  return collapse ? (
    <>
      <ST.CollapsedImageWrapper height={height}>
        {sliderImage}
      </ST.CollapsedImageWrapper>
      <ST.CollapsedTextWrapper backgroundColor={tileColor}>
        <ST.CollapsedHolder>{textSection}</ST.CollapsedHolder>
        {/* <AbsoluteTilePattern tileColor={tileColor} /> */}
      </ST.CollapsedTextWrapper>
    </>
  ) : (
    <ST.Wrapper height={height}>
      <ST.LeftSection flex={leftFlex} flushLeft={flushLeft}>
        {leftText ? (
          <ST.Section
            tileColor={tileColor}
            paddingTop={overwriteTextPadding.top || '30px'}
            paddingRight={overwriteTextPadding.right || '30px'}
            paddingBottom={overwriteTextPadding.bottom || '30px'}
            paddingLeft={overwriteTextPadding.left || '30px'}
          >
            {textSection}
          </ST.Section>
        ) : (
          sliderImage
        )}
      </ST.LeftSection>
      <ST.RightSection flex={rightFlex} flushRight={flushRight}>
        {!leftText ? (
          <ST.Section
            tileColor={tileColor}
            paddingTop={overwriteTextPadding.top || '30px'}
            paddingRight={overwriteTextPadding.right || '30px'}
            paddingBottom={overwriteTextPadding.bottom || '30px'}
            paddingLeft={overwriteTextPadding.left || '30px'}
          >
            {textSection}
          </ST.Section>
        ) : (
          sliderImage
        )}
      </ST.RightSection>
    </ST.Wrapper>
  );
};

ImageTextRow.defaultProps = {};
ImageTextRow.propTypes = {};

export default memo(ImageTextRow);
