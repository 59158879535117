/* eslint-disable indent */
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  COLORS,
  FONTS,
  FONT_SIZE,
  OVERFLOW_STYLE,
  PADDING,
  SHADOW_GENERATOR,
} from '@constants/styles';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import CustomForm from '@components/CustomForm';

import { FormOptionType } from '@components/CustomForm/types';
import { attemptConfirmVerificationThunk } from '../state/thunks';

import { selectLoggedIn } from '../../App/state/selectors';

const { PUBLIC_URL } = process.env;
const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    background-color: ${COLORS.MAIN};
    background-image: ${`url("${PUBLIC_URL}/images/retirement_background.jpg")`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.35); /* Tint color */
    background-blend-mode: multiply;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    ${OVERFLOW_STYLE(false, true, true)}
  `,
  Content: styled.div`
    max-height: 90%;
    max-width: 90%;
    width: 400px;
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.WHITE};
    border-radius: 8px;
    ${SHADOW_GENERATOR(1, 1, 1)}
  `,
  HeaderSection: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: ${PADDING.LG};
    font-size: ${FONT_SIZE.PX24};
    font-family: ${FONTS.NORMAL};
    color: ${COLORS.BLACK};
  `,
  FormWrapper: styled.div``,
  FooterSection: styled.div`
    width: 100%;
    height: 50px;
    border-top: 1px solid ${COLORS.SILVER};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${FONT_SIZE.PX16};
    padding: ${PADDING.XL} 0px;
  `,
  FooterText: styled.span`
    margin-right: 4px;
    color: ${COLORS.BLACK};
    font-family: ${FONTS.NORMAL};
    color: ${COLORS.BLACK};
  `,
  FooterButton: styled.span`
    font-family: ${FONTS.BOLD};
    color: ${COLORS.MAIN};
    cursor: pointer;

    :hover {
      color: ${COLORS.BLACK};
    }
  `,
};

interface Props {}

const VerifyEmailPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email] = useState(searchParams.get('email') || '');
  const [verificationCode] = useState(searchParams.get('code') || '');
  const [currentState, setCurrentState] = useState('PENDING');

  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(selectLoggedIn);

  const goToLogin = () => {
    navigate('/auth/login');
  };

  useEffect(() => {
    const attemptVerify = async () => {
      try {
        await dispatch(
          attemptConfirmVerificationThunk({
            email,
            code: verificationCode,
          }),
        ).unwrap();
      } catch (err) {
        setCurrentState('ERROR');
        return;
      }
      setCurrentState('SUCCESS');
    };
    if (loggedIn) {
      navigate('/account');
    } else {
      attemptVerify();
    }
  }, []);

  return (
    <ST.Wrapper>
      <ST.Container>
        <ST.Content>
          <ST.HeaderSection>{'Verify Email'}</ST.HeaderSection>
          <ST.FormWrapper>
            <CustomForm
              saveText={'Go To Login'}
              saveFcn={goToLogin}
              options={[
                { key: 'spacer', type: 'spacer' },
                ...((currentState === 'SUCCESS'
                  ? [
                      {
                        key: 'success_text',
                        type: 'rawText',
                        value: [
                          {
                            text: 'Congratulations, your email was verified. Click the button below to login and get started.',
                            fontSize: FONT_SIZE.PX16,
                          },
                        ],
                      },
                    ]
                  : []) as Array<FormOptionType>),
                ...((currentState === 'ERROR'
                  ? [
                      {
                        key: 'failure_text',
                        type: 'rawText',
                        value: [
                          {
                            text: 'Unfortunately this link is no longer valid. Click the button below to login and get a new link.',
                            fontSize: FONT_SIZE.PX16,
                          },
                        ],
                        textAlign: 'center',
                      },
                    ]
                  : []) as Array<FormOptionType>),
              ]}
              validationFcn={() => ({})}
            />
          </ST.FormWrapper>
        </ST.Content>
      </ST.Container>
    </ST.Wrapper>
  );
};

export default memo(VerifyEmailPage);
