import axios from 'axios';
import { HTTP_API_URL } from '@constants/endpoints';
import { apiErrorProcessing } from '@constants/error_messages';

export type PreviousResultsTableType = Array<{
  [key: string]: {
    title: 'string';
    keys: Array<string>;
    data: Array<{ [key: string]: any }>;
  };
}>;

export interface getUsageType {}
export interface getUsageResponseType {
  basic: number;
  custom: { complete: number; pending: number };
  subscriptions: { active: number; inactive: number };
}
export interface getPreviousResultsType {
  profileID: string;
}

export interface getPreviousResultsResponseType {
  resultsTable: PreviousResultsTableType;
}

export default class SurveyPageAPIs {
  static getUsageAPI(token: string, payload: getUsageType) {
    return axios
      .post(`${HTTP_API_URL}/admin/getUsage`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getUsageResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static getPreviousResultsAPI(token: string, payload: getPreviousResultsType) {
    return axios
      .post(`${HTTP_API_URL}/admin/getPreviousResults`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getPreviousResultsResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
