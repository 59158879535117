import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { uniq } from 'lodash';
import useSize from '@react-hook/size';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { COLORS, PADDING, SHADOW_GENERATOR_OBJ } from '@constants/styles';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    padding: ${PADDING.MD};
  `,
  Container: styled.div`
    height: 100%;
    width: 100%;
  `,
};

interface Props {
  value: Array<number>;
  updateValue: (a: Array<number>) => void;
  min?: number;
  max?: number;
  step?: number;
  railColor?: string;
  fixToEnds?: boolean;
  handleOverhang?: number;
}

const CustomMultiSlider: React.FC<Props> = ({
  value,
  updateValue,
  min = 0,
  max = 10,
  step = 1,
  railColor = COLORS.GRAY,
  fixToEnds = false,
  handleOverhang = 5,
}) => {
  const containerRef = useRef(null);
  const [, height] = useSize(containerRef);

  const adjustVal = (arr: Array<number>) => {
    const temp = [...arr];
    temp[0] = min;
    temp[temp.length - 1] = max;

    return temp;
  };
  const sliderUpdated = (val: number | Array<number>) => {
    if (uniq(val as Array<number>).length === (val as Array<number>).length) {
      if (fixToEnds) {
        updateValue(adjustVal(val as Array<number>));
      } else {
        updateValue(val as Array<number>);
      }
    }
  };

  useEffect(() => {
    if (fixToEnds) {
      updateValue(adjustVal(value as Array<number>));
    }
  }, []);

  return (
    <ST.Wrapper>
      <ST.Container ref={containerRef}>
        <Slider
          range
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={sliderUpdated}
          allowCross={false}
          trackStyle={COLORS.SLIDER_RANGE.slice(0, value.length).map(d => ({
            backgroundColor: d,
            height: `${height}px`,
          }))}
          handleStyle={{
            backgroundColor: COLORS.WHITE,
            opacity: 1,
            borderColor: COLORS.BLACK,
            borderWidth: '1px',
            height: `${height + 2 * handleOverhang}px`,
            marginTop: `${-handleOverhang}px`,
            borderRadius: '4px',
            ...SHADOW_GENERATOR_OBJ(1, 1, 2),
          }}
          railStyle={{ backgroundColor: railColor, height: `${height}px` }}
        />
      </ST.Container>
    </ST.Wrapper>
  );
};

export default memo(CustomMultiSlider);
