import { createGlobalStyle } from 'styled-components';

import OpenSansLight from '@fonts/OpenSans-Light.ttf';
import OpenSansRegular from '@fonts/OpenSans-Regular.ttf';
import OpenSansMedium from '@fonts/OpenSans-SemiBold.ttf';
import OpenSansBold from '@fonts/OpenSans-Bold.ttf';
import OpenSansExtraBold from '@fonts/OpenSans-ExtraBold.ttf';

import { FONTS } from '@constants/styles';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    font-size: 16px;
  }

  @font-face {
    font-family: LightText;
    src: url(${OpenSansLight}) format("truetype")
  }

  @font-face {
    font-family: NormalText;
    src: url(${OpenSansRegular}) format("truetype")
  }

  @font-face {
    font-family: MediumText;
    src: url(${OpenSansMedium}) format("truetype")
  }

  @font-face {
    font-family: BoldText;
    src: url(${OpenSansBold}) format("truetype")
  }

  @font-face {
    font-family: SuperBoldText;
    src: url(${OpenSansExtraBold}) format("truetype")
  }

  // @media screen and (max-width: 950px) and (min-width: 700px) {
  //   html {
  //     font-size: 14px;
  //   }
  // }

  body {
    font-family: ${FONTS.NORMAL}, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body.fontLoaded {
    font-family: ${FONTS.NORMAL}, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: #ffffff;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid white;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
      display: none;
    }
  }
`;

export default GlobalStyle;
