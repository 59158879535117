import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';

import { COLORS, NO_SELECT } from '@constants/styles';
import { BUTTON_TYPE } from '@components/MultiButtonRow';
import CustomTitle from '../../components/CustomTitle';

const ST = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    :hover {
      background-color: ${COLORS.LIGHT_GRAY};
    }
    ${NO_SELECT}
  `,
  Input: styled.div<{ width?: number }>`
    ${p => (p.width ? `width: ${p.width}px` : '')};
  `,
};

interface Props {
  title?: {
    label: string;
    size?: string;
    color?: string;
    font?: string;
    buttons?: Array<BUTTON_TYPE>;
    selectable?: boolean;
  };
  helpText?: { key: string; text: string };
  children?: ReactNode;
  inputWidth?: number;
}

const InlineProperty: React.FC<Props> = ({
  title,
  helpText,
  children,
  inputWidth,
}) => (
  <ST.Wrapper>
    {title && (
      <CustomTitle
        label={title.label}
        size={title.size}
        color={title.color}
        font={title.font}
        buttons={title.buttons}
        selectable={title.selectable}
        helpText={helpText}
      />
    )}
    <ST.Input width={inputWidth}>{children}</ST.Input>
  </ST.Wrapper>
);

export default memo(InlineProperty);
