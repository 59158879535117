import React, { memo } from 'react';
import styled from 'styled-components';
import { COLORS } from '@constants/styles';
import { useAppSelector } from '@app/hooks';
import { selectCollapse } from '@containers/App/state/selectors';

const ST = {
  Wrapper: styled.div<{
    collapse: boolean;
    num: number;
    backgroundColor: string;
  }>`
    width: 100%;
    height: ${p => (p.collapse ? 4 : 4) * p.num}px;
    background-color: ${p => p.backgroundColor};
  `,
};

interface Props {
  num?: number;
  backgroundColor?: string;
}

const FormSpacer: React.FC<Props> = ({
  num = 2,
  backgroundColor = COLORS.TRANSPARENT,
}) => {
  const collapse = useAppSelector(selectCollapse);

  return (
    <ST.Wrapper
      collapse={collapse}
      num={num}
      backgroundColor={backgroundColor}
    />
  );
};

export default memo(FormSpacer);
