import axios from 'axios';
import { HTTP_API_URL } from '@constants/endpoints';
import { apiErrorProcessing } from '@constants/error_messages';
import { TipsPageSectionType } from './reducer';

export interface getTipsPageType {}
export interface getTipsPageResponseType {
  isAdmin: boolean;
  sections: Array<TipsPageSectionType>;
}
export interface updateTipsPageType {
  sections: Array<TipsPageSectionType>;
}

export interface updateTipsPageResponseType {
  sections: Array<TipsPageSectionType>;
}

export default class TipsPageAPIs {
  static getTipsPageAPI(token: string | null, payload: getTipsPageType) {
    return axios
      .post(
        token
          ? `${HTTP_API_URL}/pages/getTipsPage`
          : `${HTTP_API_URL}/pages/getUnauthorizedTipsPage`,
        payload,
        token
          ? {
              headers: { Authorization: `Bearer ${token}` },
            }
          : {},
      )
      .then((result: { data: getTipsPageResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateTipsPageAPI(token: string, payload: updateTipsPageType) {
    return axios
      .post(`${HTTP_API_URL}/admin/updateTipsPage`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: updateTipsPageResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
