import React, { memo } from 'react';
import styled from 'styled-components';

import toggleArray from '@utils/fcns/toggleArray';
import CheckboxRow from '../CheckboxRow';

const ST = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
};

interface Props {
  data: Array<{
    key: string;
    label: string;
  }>;
  selected: Array<string>;
  optionChanged: (a: Array<string>) => void;
}

const MultiCheckboxGroup: React.FC<Props> = ({
  data,
  selected,
  optionChanged,
}) => {
  const updateCheckboxSelection = (val: string) => {
    optionChanged(toggleArray(selected, val));
  };

  return (
    <ST.Wrapper>
      {data.map(d => (
        <CheckboxRow
          key={d.key}
          checkKey={d.key}
          updateCheck={updateCheckboxSelection}
          checked={selected.includes(d.key)}
          label={d.label}
        />
      ))}
    </ST.Wrapper>
  );
};

export default memo(MultiCheckboxGroup);
