/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, sum } from 'lodash-es';

import {
  checkSubscriptionStatusResponseType,
  getCompletedReportResponseType,
  orderReportResponseType,
  ReportTypes,
} from './apis';
import {
  SurveyAnswersType,
  SurveyDefaultAnswersFcn,
} from '../constants/surveyAnswers';
import {
  getCompletedReportThunk,
  orderReportThunk,
  deletePERCReportThunk,
  downloadPERCReportThunk,
  checkSubscriptionStatusThunk,
} from './thunks';
import { SURVEY_PAGES } from '../constants/surveyPages';

export type PERCKeyDataTableType = Array<{
  label: string;
  s1: string | number;
  s2: string | number;
}>;

export type ReportDataType = {
  scenario: 'BASIC1' | 'BASIC2' | 'CUSTOM';
  age: number;
  total_placeholder: number;
  old_annuity: number;
  new_annuity: number;
  cpp: number;
  oas: number;
  db_pension: number;
  net_employment: number;
  property_1: number;
  property_2: number;
  rrif_income: number;
  lif_income: number;
  tfsa_income: number;
  nts_income: number;
  rrif_amount: number;
  lif_amount: number;
  tfsa_amount: number;
  nts_amount: number;
};

export interface SurveyPageState {
  pending: boolean;
  quietPending: boolean;
  surveyType: 'new' | 'edit';
  currentPage: number;
  surveyAnswers: SurveyAnswersType;
  reportType?: ReportTypes;
  reportData: Array<ReportDataType>;
  algVersion: number;
  keyDataTable: PERCKeyDataTableType;
  profileID: string;
  isSubscribed: boolean;
  subscriptionExpiry: string;
  pagesViewed: { [key: string]: boolean };
  startPage: number | null;
  hasLoaded: boolean;
}

const getAssetSums = (
  ans: SurveyAnswersType,
): {
  taxableAssetTotal: { p: number; s: number };
  ntsTotal: { p: number; s: number };
} => ({
  taxableAssetTotal: {
    p: sum([
      ans.p_current_rrsp_rrif,
      ans.p_current_dc_pension_lif,
      ans.p_current_tfsa,
    ]),

    s: sum([
      ans.s_current_rrsp_rrif,
      ans.s_current_dc_pension_lif,
      ans.s_current_tfsa,
    ]),
  },
  ntsTotal: {
    p: ans.p_current_nts_assets || 0,
    s: ans.s_current_nts_assets || 0,
  },
});

const initialState: SurveyPageState = {
  pending: false,
  quietPending: false,
  surveyType: 'new',
  currentPage: 0,
  surveyAnswers: SurveyDefaultAnswersFcn({
    taxableAssetTotal: { p: 0, s: 0 },
    ntsTotal: { p: 0, s: 0 },
  }),
  reportType: undefined,
  reportData: [],
  algVersion: 0,
  keyDataTable: [],
  profileID: '',
  isSubscribed: false,
  subscriptionExpiry: '',
  pagesViewed: {},
  startPage: 0,
  hasLoaded: false,
};

const slice = createSlice({
  name: 'surveyPage',
  initialState,
  reducers: {
    setLocalPendingAction: (
      state: SurveyPageState,
      action: PayloadAction<boolean>,
    ) => {
      state.pending = action.payload;
    },
    closeSurveyAction: (state: SurveyPageState) => {
      state.currentPage = 0;

      state.surveyAnswers = SurveyDefaultAnswersFcn(
        getAssetSums(state.surveyAnswers),
      );
      state.pagesViewed = {};
      state.reportType = undefined;
      state.reportData = [];
      state.algVersion = 0;
      state.keyDataTable = [];
      state.profileID = '';
      state.isSubscribed = false;
      state.subscriptionExpiry = '';
      state.surveyType = 'new';
      state.hasLoaded = false;
    },
    initializeSurveyAction: (state: SurveyPageState) => {
      state.currentPage = 0;

      state.surveyAnswers = SurveyDefaultAnswersFcn(
        getAssetSums(state.surveyAnswers),
      );
      state.pagesViewed = {};
      state.reportType = undefined;
      state.reportData = [];
      state.algVersion = 0;
      state.keyDataTable = [];
      state.profileID = '';
      state.isSubscribed = false;
      state.subscriptionExpiry = '';
      state.surveyType = 'new';
      state.hasLoaded = true;
    },
    updateSurveyAnswersAction: (
      state: SurveyPageState,
      action: PayloadAction<{
        answers: SurveyAnswersType;
        nextPage: number;
        pageKey: string;
      }>,
    ) => {
      if (!isEqual(state.surveyAnswers, action.payload.answers)) {
        state.reportType = undefined;
        state.reportData = [];
        state.algVersion = 0;
        state.keyDataTable = [];
        state.surveyType = 'new';
      }
      state.surveyAnswers = SurveyDefaultAnswersFcn({
        ...getAssetSums(action.payload.answers),
        override: action.payload.answers,
      });
      state.currentPage = action.payload.nextPage;
      state.pagesViewed = {
        ...state.pagesViewed,
        [action.payload.pageKey]: true,
      };
    },
    updateCurrentPageAction: (
      state: SurveyPageState,
      action: PayloadAction<{ num: number; key?: string }>,
    ) => {
      state.currentPage = action.payload.num;
      if (action.payload.key) {
        state.pagesViewed = {
          ...state.pagesViewed,
          [action.payload.key]: true,
        };
      }
    },
    updatePagesViewedAction: (
      state: SurveyPageState,
      action: PayloadAction<{ pages: Array<string>; override: boolean }>,
    ) => {
      const oldPages: { [key: string]: boolean } = { ...state.pagesViewed };
      const oldPageKeys: Array<string> = Object.keys(oldPages);
      const newPages: { [key: string]: boolean } = {};
      const newPageKeys = action.payload.pages.filter(d => !(d in oldPages));

      oldPageKeys.forEach(d => {
        if (!action.payload.pages.includes(d)) {
          // Page has been removed
          oldPages[d] = false;
        } else {
          // Page is already being tracked. If we are in edit mode that means it has already been accepted
          oldPages[d] = oldPages[d] || state.surveyType === 'edit';
        }
      });

      newPageKeys.forEach(d => {
        newPages[d] = action.payload.override;
      });

      state.pagesViewed = { ...oldPages, ...newPages };
    },
  },
  extraReducers: builder => {
    builder.addCase(orderReportThunk.pending, (state: SurveyPageState) => {
      state.quietPending = true;
    });
    builder.addCase(
      orderReportThunk.fulfilled,
      (
        state: SurveyPageState,
        action: PayloadAction<orderReportResponseType>,
      ) => {
        state.quietPending = false;
        state.profileID = action.payload.profileID;
      },
    );
    builder.addCase(orderReportThunk.rejected, (state: SurveyPageState) => {
      state.quietPending = false;
      state.pending = false;
    });
    builder.addCase(
      getCompletedReportThunk.pending,
      (state: SurveyPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getCompletedReportThunk.fulfilled,
      (
        state: SurveyPageState,
        action: PayloadAction<getCompletedReportResponseType>,
      ) => {
        state.pending = false;
        state.surveyAnswers = SurveyDefaultAnswersFcn({
          ...getAssetSums(action.payload.answers),
          override: action.payload.answers,
        });
        state.reportType = action.payload.reportType;
        if ((action as any).meta.arg.refreshParams.isNew) {
          state.reportData = [];
          state.algVersion = 0;
          state.keyDataTable = [];
        } else {
          state.reportData = action.payload.reportData;
          state.algVersion = action.payload.algVersion;
          state.keyDataTable = action.payload.keyDataTable;
        }
        state.profileID = action.payload.profileID;
        state.isSubscribed = action.payload.isSubscribed;
        state.subscriptionExpiry = action.payload.subscriptionExpiry;
        state.surveyType = 'edit';
        state.startPage = (action as any).meta.arg.refreshParams.startPage;
        state.hasLoaded = true;
      },
    );
    builder.addCase(
      getCompletedReportThunk.rejected,
      (state: SurveyPageState) => {
        state.pending = false;
        state.currentPage = 0;
        state.surveyAnswers = SurveyDefaultAnswersFcn(
          getAssetSums(state.surveyAnswers),
        );
        state.reportType = undefined;
        state.reportData = [];
        state.algVersion = 0;
        state.keyDataTable = [];
        state.profileID = '';
        state.isSubscribed = false;
        state.subscriptionExpiry = '';
        state.startPage = 0;
        state.hasLoaded = true;
      },
    );
    builder.addCase(
      checkSubscriptionStatusThunk.pending,
      (state: SurveyPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      checkSubscriptionStatusThunk.fulfilled,
      (
        state: SurveyPageState,
        action: PayloadAction<checkSubscriptionStatusResponseType>,
      ) => {
        state.pending = false;
        state.isSubscribed = action.payload.isSubscribed;

        let newSurveyAnswers = { ...state.surveyAnswers };
        const tempUpdateAnswers = (val: SurveyAnswersType) => {
          newSurveyAnswers = val;
        };
        const currentIncludeSpouse = newSurveyAnswers.include_spouse;

        [
          'p_birthdate',
          'p_gender',
          'p_years_in_canada',
          's_birthdate',
          's_gender',
          's_years_in_canada',
          'include_spouse',
        ].forEach(d => {
          SURVEY_PAGES(newSurveyAnswers, state.isSubscribed, () => {})[1]
            .questions(
              { ...newSurveyAnswers, include_spouse: 'y' },
              tempUpdateAnswers,
            )
            .forEach(g => {
              if (g.key === d) {
                if (g.key === 'include_spouse') {
                  if (action.payload.include_spouse !== 'y') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    g.updateValue('n');
                    newSurveyAnswers.hide_spouse_question = true;
                  } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    g.updateValue(currentIncludeSpouse);
                    newSurveyAnswers.hide_spouse_question = false;
                  }
                } else if (
                  [
                    'p_birthdate',
                    'p_gender',
                    'p_years_in_canada',
                    's_birthdate',
                    's_gender',
                    's_years_in_canada',
                  ].includes(g.key)
                ) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  g.updateValue(action.payload[g.key]);
                } else if (
                  ['p_years_in_canada', 's_years_in_canada'].includes(g.key)
                ) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  g.updateValue(+action.payload[g.key]);
                }
              }
            });
        });

        state.surveyAnswers = {
          ...newSurveyAnswers,
        };

        state.currentPage = 1;
        state.pagesViewed = {
          terms_of_use_and_disclaimer: true,
        };
        state.reportType = undefined;
        state.reportData = [];
        state.algVersion = 0;
        state.keyDataTable = [];
        state.surveyType = 'new';
      },
    );
    builder.addCase(
      checkSubscriptionStatusThunk.rejected,
      (state: SurveyPageState) => {
        state.pending = false;
        state.isSubscribed = false;
      },
    );
    builder.addCase(deletePERCReportThunk.pending, (state: SurveyPageState) => {
      state.pending = true;
    });
    builder.addCase(
      deletePERCReportThunk.fulfilled,
      (state: SurveyPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      deletePERCReportThunk.rejected,
      (state: SurveyPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      downloadPERCReportThunk.pending,
      (state: SurveyPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      downloadPERCReportThunk.fulfilled,
      (state: SurveyPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      downloadPERCReportThunk.rejected,
      (state: SurveyPageState) => {
        state.pending = false;
      },
    );
  },
});

export const {
  setLocalPendingAction,
  initializeSurveyAction,
  closeSurveyAction,
  updateCurrentPageAction,
  updateSurveyAnswersAction,
  updatePagesViewedAction,
} = slice.actions;

export default slice.reducer;
