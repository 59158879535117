/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  attemptForgotPasswordThunk,
  attemptLoginThunk,
  attemptResetPasswordThunk,
  attemptResendVerificationThunk,
  attemptRegisterThunk,
  attemptConfirmVerificationThunk,
} from './thunks';

interface AuthPagesState {
  pending: boolean;
  verificationModal: { isOpen: boolean };
}

const initialState: AuthPagesState = {
  pending: false,
  verificationModal: { isOpen: false },
};

const slice = createSlice({
  name: 'authPages',
  initialState,
  reducers: {
    setVerificationModalAction: (
      state: AuthPagesState,
      action: PayloadAction<boolean>,
    ) => {
      state.verificationModal = { isOpen: action.payload };
    },
  },
  extraReducers: builder => {
    builder.addCase(attemptLoginThunk.pending, (state: AuthPagesState) => {
      state.pending = true;
    });
    builder.addCase(attemptLoginThunk.fulfilled, (state: AuthPagesState) => {
      state.pending = false;
    });
    builder.addCase(
      attemptLoginThunk.rejected,
      (state: AuthPagesState, action: any) => {
        state.pending = false;
        if (action.payload === 'UNCONFIRMED_USER') {
          state.verificationModal = { isOpen: true };
        }
      },
    );
    builder.addCase(attemptRegisterThunk.pending, (state: AuthPagesState) => {
      state.pending = true;
    });
    builder.addCase(attemptRegisterThunk.fulfilled, (state: AuthPagesState) => {
      state.pending = false;
    });
    builder.addCase(attemptRegisterThunk.rejected, (state: AuthPagesState) => {
      state.pending = false;
    });
    builder.addCase(
      attemptResendVerificationThunk.pending,
      (state: AuthPagesState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      attemptResendVerificationThunk.fulfilled,
      (state: AuthPagesState) => {
        state.pending = false;
        state.verificationModal = { isOpen: false };
      },
    );
    builder.addCase(
      attemptResendVerificationThunk.rejected,
      (state: AuthPagesState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      attemptConfirmVerificationThunk.pending,
      (state: AuthPagesState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      attemptConfirmVerificationThunk.fulfilled,
      (state: AuthPagesState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      attemptConfirmVerificationThunk.rejected,
      (state: AuthPagesState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      attemptForgotPasswordThunk.pending,
      (state: AuthPagesState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      attemptForgotPasswordThunk.fulfilled,
      (state: AuthPagesState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      attemptForgotPasswordThunk.rejected,
      (state: AuthPagesState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      attemptResetPasswordThunk.pending,
      (state: AuthPagesState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      attemptResetPasswordThunk.fulfilled,
      (state: AuthPagesState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      attemptResetPasswordThunk.rejected,
      (state: AuthPagesState) => {
        state.pending = false;
      },
    );
  },
});

export const { setVerificationModalAction } = slice.actions;

export default slice.reducer;
