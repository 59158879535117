import React, { memo } from 'react';
import styled from 'styled-components';
import { PulseLoader } from 'react-spinners';
import { COLORS } from '@constants/styles';

const ST = {
  Wrapper: styled.tr``,
  Container: styled.td`
    height: 50px;
    text-align: center;
  `,
  Span: styled.span``,
};

interface Props {
  colSpan: number;
  color?: string;
}

const TableLoader: React.FC<Props> = ({ colSpan, color = COLORS.MAIN }) => (
  <ST.Wrapper>
    <ST.Container colSpan={colSpan}>
      <ST.Span>
        <PulseLoader color={color} size={20} speedMultiplier={1} />
      </ST.Span>
    </ST.Container>
  </ST.Wrapper>
);

export default memo(TableLoader);
