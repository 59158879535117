import React, { memo } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks';
import { selectCollapse } from '@containers/App/state/selectors';

const ST = {
  Wrapper: styled.div<{ collapse: boolean }>`
    width: 100%;
    height: ${p => (p.collapse ? 20 : 20)}px;
  `,
};

interface Props {}

const Spacer: React.FC<Props> = () => {
  const collapse = useAppSelector(selectCollapse);

  return <ST.Wrapper collapse={collapse} />;
};

export default memo(Spacer);
