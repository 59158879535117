import { RootState } from '@app/store';

export const selectLocalPending = (state: RootState) =>
  state.surveyPage.pending;
export const selectQuietPending = (state: RootState) =>
  state.surveyPage.quietPending;
export const selectSurveyType = (state: RootState) =>
  state.surveyPage.surveyType;
export const selectSurveyAnswers = (state: RootState) =>
  state.surveyPage.surveyAnswers;
export const selectCurrentPage = (state: RootState) =>
  state.surveyPage.currentPage;
export const selectAlgorithmVersion = (state: RootState) =>
  state.surveyPage.algVersion;
export const selectReportData = (state: RootState) =>
  state.surveyPage.reportData;
export const selectKeyDataTable = (state: RootState) =>
  state.surveyPage.keyDataTable;
export const selectProfileID = (state: RootState) => state.surveyPage.profileID;
export const selectIsSubscribed = (state: RootState) =>
  state.surveyPage.isSubscribed;
export const selectSubscriptionExpiry = (state: RootState) =>
  state.surveyPage.subscriptionExpiry;
export const selectPagesViewed = (state: RootState) =>
  state.surveyPage.pagesViewed;
export const selectStartPage = (state: RootState) => state.surveyPage.startPage;
export const selectHasLoaded = (state: RootState) => state.surveyPage.hasLoaded;
export const selectReportType = (state: RootState) =>
  state.surveyPage.reportType;
