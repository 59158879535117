import { createAsyncThunk } from '@reduxjs/toolkit';
import { processThunkError } from '@utils/processThunkError';
import { genericTokenCheck } from '@containers/App/state/thunks';

import DownloadPageAPIs, { getPreviousResultsType, getUsageType } from './apis';

export const getUsageThunk = createAsyncThunk(
  'downloadPage/getUsage',
  async (payload: getUsageType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await DownloadPageAPIs.getUsageAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'DOWNLOAD_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const getPreviousResultsThunk = createAsyncThunk(
  'downloadPage/getPreviousResults',
  async (payload: getPreviousResultsType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await DownloadPageAPIs.getPreviousResultsAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'DOWNLOAD_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
