import React, { ReactNode, memo } from 'react';
import styled from 'styled-components';
import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';

import InputWrapper from '../InputWrapper';

const ST = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  Input: styled.textarea<{
    backgroundColor: string;
    color: string;
    fontFamily: string;
    textAlign: string;
    fontSize: string;
    enableResize: boolean;
  }>`
    width: 100%;
    background-color: transparent;
    border: 0px;

    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
    text-align: ${p => p.textAlign};
    font-family: ${p => p.fontFamily};
    font-size: ${p => p.fontSize};
    padding: ${PADDING.MD} ${PADDING.SM};

    ${p => (p.enableResize ? 'resize: vertical;' : 'resize: none;')}

    :focus {
      outline: none;
      border: none;
    }

    :disabled {
      border: 0px;
      ${NO_SELECT}
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
  `,
  CounterWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: ${FONT_SIZE.PX12};
    font-family: ${FONTS.NORMAL};
    color: ${COLORS.BLACK};
  `,
};

interface Props {
  value: string;
  icon?: ReactNode;
  onChange?: (a: string) => void;
  textAlign?: string;
  fontSize?: string;
  fontFamily?: string;
  backgroundColor?: string;
  color?: string;
  disabled?: boolean;
  placeholder?: string;
  enterListener?: () => void;
  rows?: number;
  maxLength?: number;
  enableResize?: boolean;
}

const CustomTextArea: React.FC<Props> = ({
  value,
  icon,
  onChange = () => {},
  textAlign = 'left',
  fontSize = FONT_SIZE.PX14,
  fontFamily = FONTS.NORMAL,
  backgroundColor = COLORS.TRANSPARENT,
  color = COLORS.BLACK,
  disabled = false,
  placeholder,
  enterListener = () => {},
  rows = 4,
  maxLength = 1000,
  enableResize = false,
}) => {
  const updateInput = (ev: any) => {
    onChange(ev.target.value);
  };

  const enterFcn = (e: { key: string }) => {
    if (e.key === 'Enter') {
      enterListener();
    }
  };

  return (
    <ST.Wrapper>
      <InputWrapper leadingIcon={icon} hideBorder={disabled}>
        <ST.Input
          value={`${value}`}
          onChange={updateInput}
          textAlign={textAlign}
          onWheel={e => (e.target as HTMLElement).blur()}
          backgroundColor={backgroundColor}
          color={color}
          fontSize={fontSize}
          fontFamily={fontFamily}
          disabled={disabled}
          placeholder={placeholder}
          onKeyUp={enterFcn}
          rows={rows}
          maxLength={maxLength}
          enableResize={enableResize && !disabled}
        />
      </InputWrapper>
      <ST.CounterWrapper>{`${
        `${value}`.length
      } / ${maxLength}`}</ST.CounterWrapper>
    </ST.Wrapper>
  );
};

export default memo(CustomTextArea);
