import { createAsyncThunk } from '@reduxjs/toolkit';
import { processThunkError } from '@utils/processThunkError';
// import { genericTokenCheck } from '@containers/App/state/thunks';

import ReportRedirectPageAPIs, { getNewProfileIDType } from './apis';

export const getNewProfileIDThunk = createAsyncThunk(
  'surveyPage/getNewProfileID',
  async (payload: getNewProfileIDType, { dispatch, rejectWithValue }) => {
    let results;
    // let token = null;
    const token = '';

    try {
      //   token = await genericTokenCheck(dispatch);
      results = await ReportRedirectPageAPIs.getNewProfileIDAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'SURVEY_PAGE',
        true,
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
