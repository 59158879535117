/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateTipsPageThunk, getTipsPageThunk } from './thunks';
import { getTipsPageResponseType, updateTipsPageResponseType } from './apis';

export interface TipsPageSectionType {
  sectionNum: number;
  text: string;
}

interface TipsPageState {
  pending: boolean;
  isAdmin: boolean;
  editable: boolean;
  sections: Array<TipsPageSectionType>;
  showSave: boolean;
}

const initialState: TipsPageState = {
  pending: false,
  isAdmin: false,
  editable: true,
  sections: [],
  showSave: false,
};

const slice = createSlice({
  name: 'tipsPage',
  initialState,
  reducers: {
    setEditableAction: (
      state: TipsPageState,
      action: PayloadAction<boolean>,
    ) => {
      state.editable = action.payload;
    },
    updateEditorStateAction: (
      state: TipsPageState,
      action: PayloadAction<TipsPageSectionType>,
    ) => {
      state.sections[action.payload.sectionNum] = action.payload;
      state.showSave = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(getTipsPageThunk.pending, (state: TipsPageState) => {
      state.pending = true;
      state.editable = false;
      state.isAdmin = false;
      state.sections = [];
    });
    builder.addCase(
      getTipsPageThunk.fulfilled,
      (
        state: TipsPageState,
        action: PayloadAction<getTipsPageResponseType>,
      ) => {
        state.pending = false;
        state.editable = false;
        state.isAdmin = action.payload.isAdmin;
        state.sections = action.payload.sections;
      },
    );
    builder.addCase(getTipsPageThunk.rejected, (state: TipsPageState) => {
      state.pending = false;
    });
    builder.addCase(updateTipsPageThunk.pending, (state: TipsPageState) => {
      state.pending = true;
    });
    builder.addCase(
      updateTipsPageThunk.fulfilled,
      (
        state: TipsPageState,
        action: PayloadAction<updateTipsPageResponseType>,
      ) => {
        state.pending = false;
        state.sections = action.payload.sections;
        state.showSave = false;
      },
    );
    builder.addCase(updateTipsPageThunk.rejected, (state: TipsPageState) => {
      state.pending = false;
    });
  },
});

export const { setEditableAction, updateEditorStateAction } = slice.actions;

export default slice.reducer;
