import { createAsyncThunk } from '@reduxjs/toolkit';
import { processThunkError } from '@utils/processThunkError';
import { genericTokenCheck } from '@containers/App/state/thunks';

import SubscriptionPageAPIs, {
  getSubscriptionPricingType,
  orderSubscriptionType,
} from './apis';

export const orderSubscriptionThunk = createAsyncThunk(
  'subscriptionPage/orderSubscription',
  async (payload: orderSubscriptionType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await SubscriptionPageAPIs.orderSubscriptionAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'SUBSCRIPTION_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const getSubscriptionPricingThunk = createAsyncThunk(
  'subscriptionPage/getSubscriptionPricing',
  async (
    payload: getSubscriptionPricingType,
    { dispatch, rejectWithValue },
  ) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await SubscriptionPageAPIs.getSubscriptionPricingAPI(
        token,
        payload,
      );
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'SUBSCRIPTION_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
