import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';

import { COLORS, FONTS, FONT_SIZE, PADDING } from '@constants/styles';
import CustomTitle, { CustomTitleProps } from '../../components/CustomTitle';

const ST = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
  `,
  Title: styled.div`
    width: 100%;
    margin-bottom: ${PADDING.XS};
  `,
  Description: styled.div`
    font-size: ${FONT_SIZE.PX14};
    font-family: ${FONTS.NORMAL};
    color: ${COLORS.BLACK};
    margin-bottom: ${PADDING.MD};
  `,
  Input: styled.div`
    width: 100%;
  `,
};

interface Props {
  title?: CustomTitleProps;
  helpText?: { key: string; text: string };
  children?: ReactNode;
  description?: string;
}

const StackedProperty: React.FC<Props> = ({ title, children, description }) => (
  <ST.Wrapper>
    {title && (
      <ST.Title>
        <CustomTitle
          label={title.label}
          size={title.size}
          color={title.color}
          font={title.font}
          buttons={title.buttons}
          frontButtons={title.frontButtons}
          selectable={title.selectable}
          topPadding={title.topPadding}
          bottomPadding={title.bottomPadding}
          helpText={title.helpText}
        />
      </ST.Title>
    )}
    {description && <ST.Description>{description}</ST.Description>}
    {children && <ST.Input>{children}</ST.Input>}
  </ST.Wrapper>
);

export default memo(StackedProperty);
