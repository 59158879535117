import {
  differenceInMonths,
  parse as dateParse,
  startOfMonth,
  addMonths,
} from 'date-fns';

export const getCurrentAge = (val: string | null) => {
  if (val === null) {
    return 0;
  }
  const startDate = dateParse(val, 'y-MM-dd', new Date());
  const endDate = addMonths(startOfMonth(new Date()), 1);

  return Math.floor(differenceInMonths(endDate, startDate) / 12);
};
