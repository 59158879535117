import { createAsyncThunk } from '@reduxjs/toolkit';
import { processThunkError } from '@utils/processThunkError';
import { genericTokenCheck } from '@containers/App/state/thunks';

import TipsPageAPIs, { updateTipsPageType, getTipsPageType } from './apis';

export const getTipsPageThunk = createAsyncThunk(
  'tipsPage/getTipsPage',
  async (payload: getTipsPageType, { dispatch, rejectWithValue }) => {
    let token = null;

    try {
      token = await genericTokenCheck(dispatch, true);
    } catch {
      //
    }

    let results;
    try {
      results = await TipsPageAPIs.getTipsPageAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'TIPS_PAGE');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const updateTipsPageThunk = createAsyncThunk(
  'tipsPage/updateTipsPage',
  async (payload: updateTipsPageType, { dispatch, rejectWithValue }) => {
    let results;

    try {
      const token = await genericTokenCheck(dispatch);
      results = await TipsPageAPIs.updateTipsPageAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'TIPS_PAGE');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
