import axios from 'axios';
import { HTTP_API_URL } from '@constants/endpoints';
import { apiErrorProcessing } from '@constants/error_messages';

import { SurveyAnswersType } from '../constants/surveyAnswers';
import { PERCKeyDataTableType, ReportDataType } from './reducer';

export type ReportTypes = 'BASIC' | 'CUSTOM';

export interface orderReportType extends Partial<SurveyAnswersType> {}

export interface orderReportResponseType {
  profileID: string;
  reportType: ReportTypes;
  currentlyPending: boolean;
}

export interface getCompletedReportType {
  profileID: string;
}

export interface getCompletedReportResponseType {
  answers: SurveyAnswersType;
  reportType: ReportTypes;
  reportData: Array<ReportDataType>;
  algVersion: number;
  keyDataTable: PERCKeyDataTableType;
  profileID: string;
  isSubscribed: boolean;
  subscriptionExpiry: string;
}

export interface checkSubscriptionStatusType {
  includeSpouse: string;
  pBirthdate: string;
  pGender: string;
  pYearsInCanada: number;
  sBirthdate: string;
  sGender: string;
  sYearsInCanada: number;
}
export interface checkSubscriptionStatusResponseType {
  isSubscribed: boolean;
  include_spouse: string;
  p_birthdate: string;
  p_gender: string;
  p_years_in_canada: number;
  s_birthdate: string;
  s_gender: string;
  s_years_in_canada: number;
}

export interface deletePERCReportType {
  profileID: string;
}
export interface deletePERCReportResponseType {}

export interface downloadPERCReportType {
  profileID: string;
}
export interface downloadPERCReportResponseType {}

export default class SurveyPageAPIs {
  static orderReportAPI(token: string | null, payload: orderReportType) {
    return axios
      .post(
        `${HTTP_API_URL}/survey/${
          token ? 'orderReport' : 'orderReportUnauthorized'
        }`,
        payload,
        token
          ? {
              headers: { Authorization: `Bearer ${token}` },
            }
          : {},
      )
      .then((result: { data: orderReportResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static getCompletedReport(
    token: string | null,
    payload: getCompletedReportType,
  ) {
    return axios
      .post(
        `${HTTP_API_URL}/survey/${
          token ? 'getCompletedReport' : 'getCompletedReportUnauthorized'
        }`,
        payload,
        token
          ? {
              headers: { Authorization: `Bearer ${token}` },
            }
          : {},
      )
      .then((result: { data: getCompletedReportResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static checkSubscriptionStatusAPI(
    token: string,
    payload: checkSubscriptionStatusType,
  ) {
    return axios
      .post(`${HTTP_API_URL}/survey/checkSubscriptionStatus`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        (result: { data: checkSubscriptionStatusResponseType }) => result.data,
      )
      .catch(apiErrorProcessing);
  }

  static deletePERCReportAPI(token: string, payload: deletePERCReportType) {
    return axios
      .post(`${HTTP_API_URL}/survey/deleteReport`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: deletePERCReportResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static downloadPERCReportAPI(token: string, payload: downloadPERCReportType) {
    return axios
      .post(`${HTTP_API_URL}/survey/downloadReport`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: downloadPERCReportResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
